import React from 'react';
import sec4Logo from '../../../../assets/images/sec4_logo.svg';
import SimpleSearch from '../../../forms/searchbar/SimpleSearch';
import SingleSelect from '../../../forms/select/SingleSelect';
import Button from '../../../buttons/Button';
import Filter from '../../filter/Filter';
import AnimationDiv from '../../../animation/AnimationDiv';
import { Link } from 'react-router-dom';

const Section4 = () => {
    // Options for each select dropdown

    return (
        <div className=''>
            <div className='flex justify-between mx-auto w-[90%] md:w-[80%] pt-10 pb-10 md:pb-40 1875px:pb-20'>
                <div className='text-theme_blue'>
                    <p className='text-[22px] leading-[30px] md:text-[45px] creato-f-m'>Looking for anything specific....?</p>
                    <h1 className='text-[25px] leading-[30px] md:text-5xl creato-f-b'>Use our Advanced Search engine!</h1>
                </div>
                <div className='hidden md:block'><img src={sec4Logo} alt="" /></div>
            </div>

            <div className='relative flex justify-center lg:min-h-[60px] '>
                <div className='flex p-8 md:px-12 mx-auto w-[80%] max-w-[1500px] rounded-2xl md:rounded-[49px] bg-theme_yellow gap-8 flex-wrap xl:mb-0 mb-10 xl:w- xl:absolute bottom-[-75px] right-auto pt-[28px] pb-[48px] left-auto'>
                    <Filter />
                </div>
            </div>

            <div className='sec4_bg bg-fixed  bg-cover lg:bg-size-100  bg-center  w-full bg-black bg-opacity-40 md:bg-transparent bg-blend-overlay  '>
                <div className='mx-auto w-[80%] flex justify-end'>
                    <AnimationDiv
                        initial={{ opacity: 0, x: 100 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 1 }}
                        viewport={{ once: true }}
                    >

                        <div className=' max-w-[600px] mb-16 mt-32 text-white flex flex-col items-center md:items-start'>
                            <h1 className='text-[25px] text-center md:text-start md:text-[50px] creato-f-b' style={{ lineHeight: '1.1em' }}>WELCOME TO LUXLIFE
                                MIAMI REAL ESTATE LLC</h1>
                            <div className='mt-[18px] md:mb-[34px] w-[90px] h-[7px] bg-white mx-auto md:mx-0'></div>
                            <p className='text-[22px] text-center md:text-start md:text-[35px] leading-[30px] md:leading-[47px] mt-4 creato-f-l p-[10px]' style={{
                                background: '#00000036',
                                borderRadius: '10px',
                            }}>Welcome to LuxLife Miami Real Estate LLC! With 20 years of experience, we specialize in luxury condos, apartments, homes, and investment properties across Miami. Trusted by sports stars and high-level executives, we offer exceptional service and exclusive listings. Discover your perfect property with us today!</p>
                            <Link className='' to={'/contact'} >
                                <Button className=' md:my-24 mt-2 md:mt-[60px] w-[260px] text-[20px] creato-f-m tracking-widest'
                                    title={'CONTACT US!'} /></Link>

                        </div>
                    </AnimationDiv>
                </div>
            </div>

        </div>
    );
};

export default Section4;
