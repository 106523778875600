import React, { useState, useEffect } from "react";
import logo from '../../../assets/images/logo.svg';
import sec3logo from '../../../assets/images/sec4_logo.svg';
import { IoClose, IoSearch } from "react-icons/io5";
import { AnimatePresence, motion } from 'framer-motion';
import { GiHamburgerMenu } from "react-icons/gi";
import SimpleSearch from "../../forms/searchbar/SimpleSearch";
import Button from "../../buttons/Button";
import { ImCross } from "react-icons/im";
import { Link, useLocation } from "react-router-dom";
import { FaUser } from "react-icons/fa";

const DropdownMenu = ({ items, isScrolled }) => {
    return (
        <ul className={`absolute top-full left-0 bg-white shadow-md rounded-md z-[10000000000000000] overflow-hidden ${isScrolled ? 'text-theme_yellow' : 'text-black'}`}>
            {items.map((item, index) => (
                <li
                    key={index}
                    className="relative border-b before:content-[''] before:absolute before:left-0 before:top-0 before:bottom-0 before:w-[10px] before:hover:w-[18px] before:bg-theme_yellow before:transition-width before:duration-300"
                >
                    <Link
                        to={item.link}
                        className={`block pl-6 px-4 py-2 hover:text-theme_yellow poppins-regular text-sm ${isScrolled ? '' : 'hover:text-theme_yellow'
                            }`}
                    >
                        {item.label}
                    </Link>
                </li>
            ))}
        </ul>
    );
};

const top100Items = [
    { label: '100 Newest Listings', link: '/new-100/listing' },
    { label: '100 Newest Penthouses', link: '/new-100/penthouses' },
    { label: '100 Newest Condos', link: '/new-100/condos' },
    { label: '100 Newest Homes', link: '/new-100/homes-100' },
    { label: '100 Most Expensive', link: '/new-100/expensive' },

];

const moreItems = [
    { label: 'Subscribe', link: '/subscribe' },
    { label: 'Sell my property', link: '/sell-properties' },
    { label: 'Contact ', link: '/contact' },
];

const MobileNavBar = ({ isScrolled }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isSearched, setIsSearched] = useState(false);

    const toggleNav = () => {
        setIsOpen(!isOpen);
    };

    const handleSearch = () => {
        setIsSearched(!isSearched);
    };

    return (
        <>
            <AnimatePresence>
                {isSearched && (
                    <motion.nav
                        initial={{ opacity: 0, y: '-100%' }}
                        animate={{ height: '120px', opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: '-100%' }}
                        transition={{ duration: 0.5 }}
                        className="fixed inset-0 bg-white text-white z-[100000] w-full top-0 flex justify-center items-center p-10"
                    >
                        <div className="flex-1 w-full flex gap-4 items-center">
                            <div className="flex-1">
                                <SimpleSearch
                                    className={'border border-theme_yellow rounded-full'}
                                    placeholder={'Search Properties'}
                                />
                            </div>
                            <div>
                                <Button
                                    className={'rounded-full'}
                                    onClick={handleSearch}
                                    title={<ImCross />}
                                />
                            </div>
                        </div>
                    </motion.nav>
                )}
            </AnimatePresence>

            <div className="relative">

                <div className="flex items-center gap-2 md:gap-4">
                    <div className={`p-2 text-base rounded-full flex justify-center items-center  ${isScrolled ? 'bg-theme_blue text-white' : " bg-white text-black"}`}><FaUser /></div>
                    <div
                        onClick={handleSearch}
                        className={`${isScrolled ? 'bg-theme_yellow text-white' : 'text-theme_yellow bg-white'
                            } p-2 md:p-4 rounded-full`}
                    >
                        <IoSearch className="text-[16px] md:text-[32px]" />
                    </div>
                    <button
                        className={`p-2 md:p-4 ${isScrolled
                            ? 'text-theme_yellow border-theme_yellow m-1'
                            : 'text-white border-white mr-4'
                            } rounded-full border`}
                        onClick={toggleNav}
                    >
                        <GiHamburgerMenu className="text-base md:text-3xl" />
                    </button>
                </div>

                <AnimatePresence>
                    {isOpen && (
                        <motion.nav
                            initial={{ width: 0, opacity: 0, x: '100%' }}
                            animate={{ width: '100vw', opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: '100%' }}
                            transition={{ duration: 0.5 }}
                            className="fixed inset-0 bg-gray-900 text-white z-50 flex flex-col items-center p-10"
                        >

                            <div className="flex w-full justify-end text-white">
                                <IoClose onClick={toggleNav} className="text-4xl cursor-pointer" />
                            </div>
                            <ul className="space-y-4 text-lg mt-4 text-center">
                                <li>
                                    <Link to="/condos" className="nav-item text-[25px] hover:text-theme_yellow pb-2">
                                        Condos
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/homes" className="nav-item text-[25px] hover:text-theme_yellow pb-2">
                                        Homes
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/map" className="nav-item text-[25px] hover:text-theme_yellow pb-2">
                                        Map
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/about" className="nav-item text-[25px] hover:text-theme_yellow pb-2">
                                        About
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/new-100/listing" className="nav-item text-[25px] hover:text-theme_yellow pb-2">
                                        Top 100
                                    </Link>
                                    <ul className="mt-2 space-y-2">
                                        {top100Items.map((item, index) => (
                                            <li key={index}>
                                                <Link to={item.link} className="text-[20px] hover:text-theme_yellow">
                                                    {item.label}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                                <li>
                                    <Link to="#blog" className="nav-item text-[25px] hover:text-theme_yellow pb-2">
                                        More
                                    </Link>
                                    <ul className="mt-2 space-y-2">
                                        {moreItems.map((item, index) => (
                                            <li key={index}>
                                                <Link to={item.link} className="text-[20px] hover:text-theme_yellow">
                                                    {item.label}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            </ul>
                        </motion.nav>
                    )}
                </AnimatePresence>
            </div>
        </>
    );
};


const Navbar = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const location = useLocation()
    const [activeDropdown, setActiveDropdown] = useState(null);

    const handleMouseEnter = (dropdownName) => {
        setActiveDropdown(dropdownName);
    };

    const handleMouseLeave = () => {
        setActiveDropdown(null);
    };
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const isActive = (path) => location.pathname.split('/').includes(path) ? 'border-b border-b-[3px] border-b-theme_yellow before:absolute before:content-none before:scale-x-100 ' : '';
    return (
        <div className={`w-full flex justify-center`}>


            <nav className={`p-4 pr-0 top-0 transition-all duration-300 ${isScrolled ? 'bg-white shadow-lg fixed w-[90%] px-8 py-4 z-[11000]   top-3 rounded-full ' : 'bg-transparent w-full '}`}>
                <div className="pl-6 mx-auto flex gap-4 justify-between items-center">
                    <div className={`font-bold text-xl transition-all duration-300 ${isScrolled ? 'text-theme_yellow ' : 'text-white'}`}>
                        <Link to="/"><img src={isScrolled ? sec3logo : logo} alt="logo" className={` ${isScrolled ? 'max-h-[50px]' : ''}`} /></Link>
                    </div>
                    <div className={`flex items-center text-[16px] xl:text-[25px] text-nowrap space-x-2 ${isScrolled ? ' pr-4 ' : ''}`}>
                        <ul className={`hidden md:flex gap-2 xl:space-x-4 pr-4 ${isScrolled ? 'text-theme_yellow' : 'text-white'}`}>
                            < li ><Link to="/Condos" className={`${isActive('Condos')} nav-item`}>Condos</Link></li>
                            < li ><Link to="/homes" className={`${isActive('homes')} nav-item`}>Homes</Link></li>
                            < li ><Link to="/map" className={`${isActive('map')} nav-item`}>Map</Link></li>
                            < li ><Link to="/about" className={`${isActive('about')} nav-item`}>About</Link></li>
                            <li
                                className="relative"
                                onMouseEnter={() => handleMouseEnter('top100')}
                                onMouseLeave={handleMouseLeave}
                            >
                                <Link to="/new-100/listing" className={`${isActive('new-100')} nav-item`}>Top 100</Link>
                                {activeDropdown === 'top100' && <DropdownMenu items={top100Items} isScrolled={isScrolled} />}
                            </li>
                            <li
                                className="relative"
                                onMouseEnter={() => handleMouseEnter('more')}
                                onMouseLeave={handleMouseLeave}
                            >
                                <Link to="#blog" className="nav-item">More</Link>
                                {activeDropdown === 'more' && <DropdownMenu items={moreItems} isScrolled={isScrolled} />}
                            </li>
                            < li ><Link to={'/login'}><div className={`p-3 text-lg rounded-full flex justify-center items-center  ${isScrolled ? 'bg-theme_blue text-white' : " bg-white text-black"}`}><FaUser /></div></Link></li>
                        </ul>
                        <div className={`hidden md:flex  justify-between items-center px-2 rounded-l-full ${isScrolled ? 'bg-theme_yellow rounded-full' : 'bg-white'} transition-all duration-300`}>
                            <input
                                type="text"
                                placeholder="Search Properties"
                                className={` px-4 font-[100] w-60 py-2 rounded-full focus:outline-none  ${isScrolled ? 'bg-theme_yellow text-white placeholder:text-white placeholder:opacity-75' : 'bg-white text-theme_yellow'}`}
                            />
                            <div className={`${isScrolled ? 'text-white' : 'text-theme_yellow'}`}><IoSearch className={`text-[32px] xl:text-[40px] ${isScrolled ? 'mr-1' : 'mr-4'}`} /></div>
                        </div>
                        <div className="  md:hidden">
                            <MobileNavBar isScrolled={isScrolled} />
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Navbar;
