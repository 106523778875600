import React, { useEffect, useRef, useState } from 'react'
import ContactLayout from '../components/ui/layout/ContactLayout'
import ImageViewer from '../components/image-viewer/ImageViewer'
import image1 from '../assets/images/card1.png'
import image2 from '../assets/images/card2.png'
import image3 from '../assets/images/card3.png'
import image4 from '../assets/images/card1.png'
import image5 from '../assets/images/card2.png'
import bed from '../assets/images/bed.png'
import bath from '../assets/images/bath.png'
import area from '../assets/images/area.png'
import sec4Logo from '../assets/images/sec4_logo.svg'
import Button from '../components/buttons/Button'
import { IoIosArrowForward, IoIosShareAlt } from 'react-icons/io'
import { FaLocationDot } from 'react-icons/fa6'
import { BsCircleFill } from 'react-icons/bs'
import MapComponent from '../components/map/Map'
import DatePicker from '../components/date-time-picker/DateTimePicker'
import AgentCard from '../components/cards/AgentCard'
import AnimationDiv from '../components/animation/AnimationDiv'
import Card from '../components/cards/Card'
import { cardVariants } from './New100Listing'
import { cardsList } from '../components/ui/sections/blueBgSection/card-view-section'
const images = [
    image1, image2, image3, image4, image5
];

const SingleProperty = () => {



    const overviewRef = useRef(null);
    const [isStuck, setIsStuck] = useState(true);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsStuck(entry.isIntersecting);
            },
            {
                rootMargin: '0px',
                threshold: 0.1, // Adjust this threshold as needed
            }
        );

        if (overviewRef.current) {
            observer.observe(overviewRef.current);
        }

        return () => {
            if (overviewRef.current) {
                observer.unobserve(overviewRef.current);
            }
        };
    }, []);




    return (
        <ContactLayout>
            <div className="max-w-[1600px] mx-auto py-4 px-4">
                <ImageViewer images={images} />

                <div className='flex justify-between container mx-auto p-4'>
                    <div>
                        <h1 className='creato-f-b text-[20px] md:text-[30px]'>9316 #E Boca Gardens Villa For Sale</h1>
                        <div className="flex items-center gap-2  mt-2  ">
                            <img src={bed} alt='' className='w-5' /> <p className='text-[#101010]  text-[15px] poppins-regular text-nowrap leading-[23px]'>{4} Bed</p>
                            <img src={bath} alt='' className='w-5' /> <p className='text-[#101010]  text-[15px] poppins-regular text-nowrap leading-[23px]'>{2} Bath</p>
                            <img src={area} alt='' className='w-5' /> <p className='text-[#101010]  text-[15px] poppins-regular text-nowrap leading-[23px]'>{'800'} SQFT</p>
                            <p className='text-[15px] text-green-500'>Active</p>
                        </div>
                        <div className='flex gap-4 lg:gap-16 items-center my-4'> <h1 className='creato-f-b text-[30px] md:text-[60px] text-nowrap'>$ 514,000</h1> <Button className={'creato-f-b text-theme_yellow bg-theme_blue py-2 rounded-md'} title={<div className='flex items-center gap-2 '><span>Share</span> <span><IoIosShareAlt /></span></div>} /></div>
                        <div className="flex items-center gap-1  " >
                            <FaLocationDot className='text-[20px]' /> <p className='text-[#101010] poppins-regular	 text-[15px] text-nowrap leading-[18px]'>{'Downtown Cityscape'}
                            </p>
                        </div>
                    </div>
                    <div>
                        <img src={sec4Logo} alt="" className='my-4' />
                    </div>
                </div>
                <div className='container items-center flex-wrap gap-4 flex md:justify-between mx-auto p-4 border-theme_grey2 border-b-2 pb-6 my-4'>
                    <div className="flex creato-f-b gap-2 md:gap-10 text-theme_yellow md:text-[30px] "><a href='#overview' className="">Overview</a>
                        <a href='#features' className="text-nowrap">Features & Amenities</a>
                        <a href='#location' className="">Location</a></div>
                    <div className="flex gap-6 items-center">
                        <Button className={'border border-theme_blue rounded-full text-theme_yellow creato-f-b bg-white py-3 px-6'} title={'Get in Touch'} />
                        <Button className={'rounded-full creato-f-b bg-theme_blue py-3 btn2 px-6'} title={'Schedule a Tour'} />
                    </div>
                </div>
                <div className="container flex items-center lg:items-start lg:justify-between flex-col lg:flex-row mx-auto ">
                    <div className="w-full">
                        <div>
                            <h1 className='text-[35px] lg:text-[45px] xl:text-[72px] creato-f-b text-theme_yellow'>Property Description</h1>
                            <h2 className='text-[25px]  lg:text-[50px] creato-f-b mt-2'>The Property</h2>
                            <p className='creato-f-b md:text-[18px] max-w-[900px] py-6'>
                                Enjoy paradise in one of Boca’s best communities. Refreshed 3 bedroom and 2½ bathrooms. New roof in 2024.
                                Impact windows. Patio door accordion shutter. Newly painted interior. Exterior maintenance, exterior painting
                                and lawn care provided by the HOA. Vaulted ceiling. Enjoy three community swimming pools, fitness center,
                                tennis courts, basketball courts, lots of sidewalks, and community playground. A Rated Schools, Library, Sheriff,
                                and Fire Dept. close by, Walk to Publix, Starbucks and more. LOW HOA.
                            </p>

                        </div>

                        <div id="overview" ref={overviewRef} className='max-w-[900px]'>
                            <h2 className=' text-[25px] lg:text-[50px] creato-f-b mt-2 mb-8'>Overview</h2>

                            <div className="flex md:text-[25px] px-4 py-8 border-t border-theme_blue ">
                                <div className='flex-1 '><h2 className='creato-f-b '>Property Type</h2></div>
                                <div className='flex-1'><p className="">Residencial</p></div>
                            </div>
                            <div className="flex md:text-[25px] px-4 py-8 border-t border-theme_blue ">
                                <div className='flex-1 '><h2 className='creato-f-b '>Location</h2></div>
                                <div className='flex-1'><p className="">9316 #E Boca Gardens Pkwy For Sale</p></div>
                            </div>
                            <div className="flex md:text-[25px] px-4 py-8 border-t border-theme_blue ">
                                <div className='flex-1 '><h2 className='creato-f-b '>MLS ID</h2></div>
                                <div className='flex-1'><p className="">7521146</p></div>
                            </div>
                            <div className="flex md:text-[25px] px-4 py-8 border-t border-theme_blue border-b ">
                                <div className='flex-1 '><h2 className='creato-f-b '>LISTING DATE</h2></div>
                                <div className='flex-1'><p className="">8/7/2024</p></div>
                            </div>
                        </div>
                        <div id="features" className='max-w-[900px] mt-12'>
                            <h2 className=' text-[25px] lg:text-[50px] creato-f-b mt-2 mb-8'>Property Features & Amenities</h2>


                            <div className='px-4 mt-4'>
                                <h3 className='pt-4 text-[20px] lg:text-[34px] text-theme_yellow creato-f-b'>Interior</h3>
                                <div className="flex md:text-[25px]  py-4 border-b border-theme_blue ">
                                    <div className='flex-1 flex gap-4 items-center '><BsCircleFill className='text-xs' /> <h2 className='creato-f-b '>Total Bedrooms</h2></div>
                                    <div className='flex-1'><p className="creato-f-b ">3</p></div>
                                </div>
                            </div>
                            <div className='px-4 mt-4'>
                                <h3 className='pt-4 text-[20px] lg:text-[34px] text-theme_yellow creato-f-b'>Exterior</h3>
                                <div className="flex md:text-[25px]  py-4 border-b border-theme_blue ">
                                    <div className='flex-1 flex gap-4 items-center '><BsCircleFill className='text-xs' /> <h2 className='creato-f-b '>Stories</h2></div>
                                    <div className='flex-1'><p className="creato-f-b ">2</p></div>
                                </div>
                            </div>
                            <div className='px-4 mt-4'>
                                <h3 className='pt-4 text-[20px] lg:text-[34px] text-theme_yellow creato-f-b'>Details</h3>
                                <div className="flex md:text-[25px]  py-4 border-b border-theme_blue ">
                                    <div className='flex-1 flex gap-4 items-center '><BsCircleFill className='text-xs' /> <h2 className='creato-f-b '>NEIGHBORHOOD</h2></div>
                                    <div className='flex-1'><p className="creato-f-b ">Boca Gardens 03</p></div>
                                </div>
                            </div>
                            <div className='px-4 mt-4'>
                                <h3 className='pt-4 text-[20px] lg:text-[34px] text-theme_yellow creato-f-b'>Fainancial</h3>
                                <div className="flex md:text-[25px]  py-4 ">
                                    <div className='flex-1 flex gap-4 items-center '><BsCircleFill className='text-xs' /> <h2 className='creato-f-b '>Price</h2></div>
                                    <div className='flex-1'><p className="creato-f-b ">$ 514,000</p></div>
                                </div>
                            </div>

                        </div>


                    </div>

                    <div className={`pt-0 px-4  relative`}>
                        <div className={`${isStuck ? ' sticky top-0 right-0 ' : ''} `}>
                            <DatePicker />
                            <div className='mt-24'>
                                <AgentCard />
                            </div>
                        </div>

                    </div>
                </div>
                <div id="location" className='w-full my-8'>
                    <div className='container mx-auto '>
                        <h2 className=' text-[25px] lg:text-[50px] creato-f-b mt-2 mb-8'>Location</h2>
                    </div>

                    <div className="w-full h-[400px]">
                        <MapComponent />
                    </div>
                </div>

                <div className='container py-20 mx-auto creato-f-b text-theme_blue'>
                    <h2 className=' text-[25px] lg:text-[50px] mt-2 '>Discover More!</h2>
                    <p className='max-w-[1000px] text-[24px]'>
                        Discover our expertly curated collection of properties for sale. With a focus on quality and value, we offer
                        some of the best properties on the market.
                    </p>

                    <div className='flex justify-center flex-wrap gap-x-8 mt-10'>
                        {[...cardsList.slice(0, 3)].map(v => <AnimationDiv
                            key={v.name}
                            className="lg:w-[420px]"
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.2 }}
                            variants={cardVariants}
                        > <Card data={v} key={v.name} className={`lg:w-[420px] shadow-lg`} /> </AnimationDiv>)}
                    </div>

                    <div className='flex justify-center mt-14'>
                        <Button title={<div className='flex items-center gap-4'><span>View more</span> <span><IoIosArrowForward className='text-[20px]' /></span></div>} className={'bg-theme_blue px-8 text-theme_yellow rounded-full py-3 creato-f-m  tracking-wide btn2'} />
                    </div>
                </div>
            </div>
        </ContactLayout>
    )
}

export default SingleProperty