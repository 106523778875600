import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React from "react";
import Slider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

export default function CommonCarousel({ data, type }) {
    const NextArrow = (props) => (
        <div
            {...props}
            style={{ right: "-40px", top: "calc(50% - 20px)" }}
            className="md:hidden text-center next-arrow absolute cursor-pointer mb-4 hover:bg-theme_yellow2 text-theme_yellow border border-theme_yellow w-[40px] h-[40px] rounded-full text-theme_yellow2 flex justify-center items-center"
        >
            <IoIosArrowForward />
        </div>
    );

    const PrevArrow = (props) => (
        <div
            {...props}
            style={{ left: "-40px", top: "calc(50% - 20px)" }}
            className="md:hidden prev-arrow absolute cursor-pointer mb-4 hover:bg-theme_yellow2 text-theme_yellow border border-theme_yellow w-[40px] h-[40px] rounded-full text-theme_yellow2 flex justify-center items-center"
        >
            <IoIosArrowBack />
        </div>
    );

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 860,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="w-full relative mx-auto px-12 ">
            <Slider {...settings}>
                {data.map((item, index) => (
                    <div key={index} className="flex flex-col items-center text-center p-2 black_white_effect3 cursor-pointer transition-[filter] duration-300">
                        <div>
                            <img
                                src={item.imgSrc}
                                alt={item.name}
                                className="max-w-[225px] max-h-[225px] sm:max-w-[300px] sm:max-h-[300px] mx-auto object-cover"
                            />
                        </div>
                        <div className="mt-4">
                            <h1 className="poppins-semibold text-[22px] md:text-[36px] md:leading-[45px] text-theme_yellow">
                                {item.name}
                            </h1>
                        </div>
                        <div className="mt-2 px-2 max-w-[480px]">
                            <p className="poppins-regular text-[14px] md:text-[16px] md:leading-[22px]">
                                {item.description}
                            </p>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}
