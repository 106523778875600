import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import Button from '../buttons/Button';

const DatePicker = () => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState('');
    const [tourType, setTourType] = useState('In Person');

    const daysToShow = 1;
    const timeSlots = ['9:00 AM', '11:00 AM', '1:00 PM', '3:00 PM', '5:00 PM'];

    const formatDate = (date) => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return {
            day: days[date.getDay()],
            date: date.getDate().toString().padStart(2, '0'),
            month: months[date.getMonth()],
            fullDate: date.toDateString(), // To uniquely identify each date
        };
    };

    const getDates = () => {
        const dates = [];
        for (let i = 0; i < 3; i++) {
            const date = new Date(currentDate);
            date.setDate(currentDate.getDate() + i);
            dates.push(formatDate(date));
        }
        return dates;
    };

    const handlePrevious = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() - daysToShow);
        setCurrentDate(newDate);
        setSelectedDate(null); // Reset selected date when navigating
    };

    const handleNext = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() + daysToShow);
        setCurrentDate(newDate);
        setSelectedDate(null); // Reset selected date when navigating
    };

    const handleDateSelect = (date) => {
        setSelectedDate(date.fullDate);
    };

    return (
        <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-theme_blue text-white p-6 rounded-2xl max-w-md mx-auto"
        >
            <h2 className="creato-f-b text-[30px] mb-4 text-center">Request a Tour</h2>
            <motion.div
                className="flex justify-between gap-4 mb-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, staggerChildren: 0.1 }}
            >
                {getDates().map((date, index) => (
                    <motion.div
                        key={index}
                        className={`text-center border-[3px] rounded-2xl creato-f-b p-4 min-w-[90px] lg:min-w-[120px] cursor-pointer 
                                    ${selectedDate === date.fullDate ? 'bg-theme_yellow text-theme_blue' : ''}`}
                        onClick={() => handleDateSelect(date)}
                        whileHover={{ scale: 1.05 }}
                        transition={{ type: 'tween', stiffness: 300 }}
                    >
                        <div className="text-sm">{date.day}</div>
                        <div className="creato-f-black text-[48px] py-2">{date.date}</div>
                        <div className="text-sm">{date.month}</div>
                    </motion.div>
                ))}
            </motion.div>
            <div className="flex justify-center mb-4 text-theme_blue">
                <motion.button
                    onClick={handlePrevious}
                    className="mr-2 bg-white p-2 rounded-full"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ duration: 0.2 }}
                >
                    <FaChevronLeft />
                </motion.button>
                <motion.button
                    onClick={handleNext}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className='bg-white p-2 rounded-full'
                    transition={{ duration: 0.2 }}
                >
                    <FaChevronRight />
                </motion.button>
            </div>
            <div className="mb-4 mt-8">
                <label className="block text-sm font-medium mb-1 creato-f-b">Choose a time</label>
                <motion.div className="relative" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.2 }}>
                    <select
                        value={selectedTime}
                        onChange={(e) => setSelectedTime(e.target.value)}
                        className="block appearance-none w-full bg-white border border-gray-700 text-theme_blue py-4 px-4 pr-8 rounded-full leading-tight focus:outline-none"
                    >
                        <option value="">Select</option>
                        {timeSlots.map((time, index) => (
                            <option key={index} value={time}>
                                {time}
                            </option>
                        ))}
                    </select>
                    <motion.div
                        className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 bg-theme_blue text-white rounded-full my-2 mr-4"
                        whileHover={{ rotate: 180 }}
                        transition={{ duration: 0.3 }}
                    >
                        <FaChevronDown size={20} />
                    </motion.div>
                </motion.div>
            </div>
            <motion.div
                className="flex justify-center my-4 mt-8"
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                transition={{ type: 'spring', stiffness: 200 }}
            >
                <div className='flex gap-2 bg-white p-2 px-4 rounded-full creato-f-b text-sm'>
                    <motion.button
                        onClick={() => setTourType('In Person')}
                        className={`mr-2 px-4 py-2 rounded-full ${tourType === 'In Person' ? 'bg-theme_blue text-theme_yellow' : 'text-theme_blue'}`}
                        whileHover={{ scale: 1.05 }}
                    >
                        In Person
                    </motion.button>
                    <motion.button
                        onClick={() => setTourType('Via Video Chat')}
                        className={`px-4 py-2 rounded-full ${tourType === 'Via Video Chat' ? 'bg-theme_blue text-theme_yellow' : 'text-theme_blue'}`}
                        whileHover={{ scale: 1.05 }}
                    >
                        Via Video Chat
                    </motion.button>
                </div>
            </motion.div>
            <motion.div
                className='flex mt-8'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
            >
                <Button title={'Next'} className='mx-auto py-2 px-8 text-theme_blue btn2 bg-white creato-f-b' />
            </motion.div>
        </motion.div>
    );
};

export default DatePicker;
