import React, { useEffect } from 'react'
import Header from '../components/ui/header/Header'
import Map from '../components/map/Map'
import Filter from '../components/ui/filter/Filter'
import { cardsList } from '../components/ui/sections/section5/Section5'
import Card from '../components/cards/Card'
import { RxCaretSort } from 'react-icons/rx'
import Footer from '../components/ui/sections/FooterSection/Footer'
import miamiLogo from '../assets/images/miami-logo.png'
import sec3logo from '../assets/images/sec4_logo.svg';
import SortDropdown from '../components/forms/dropdown/SortDropdown'
import { useNavigate, useParams } from 'react-router-dom'
import LuxuryListingsSlider from '../components/carousel/LuxuryListCarousel'
const InvestmentMiami = () => {
    const { slug } = useParams()
    const navigate = useNavigate()
    const routes = {
        'investment-miami': 'Investment properties in Miami',
        'villas-and-estates': 'Villas and Estates for Sale in Miami',
        'family-homes-sale': 'Family Homes for Sale in Miami',
        'luxury-appartment-sale': 'Luxury Apartments for Sale in Miami',
        'on-filter': "Here's what we found based on your applied Filter",
    }


    useEffect(() => {
        if (!routes[slug]) {
            navigate('/')
        }
    }, [])

    return (
        routes[slug] && <div>
            <div className=' bg-theme_blue'>
                <Header />
            </div>
            <div className='p-4  py-[20px] lg:pl-[70px] lg:pr-[48px] bg-theme_yellow flex gap-10 justify-center'>
                <Filter />
            </div>
            <div className='flex flex-col lg:flex-row  justify-between '>
                <div className='px-2 md:pl-[69px] pt-[45px] md:pr-[40px] flex-1   '>
                    <h1 className='creato-f-l text-[30px]'>
                        {routes[slug]}
                    </h1>
                    <div className='flex justify-between mt-[23px] text-gray-400'>
                        <p className='flex items-center'><span><SortDropdown /></span></p>
                        <p><span>230 result</span></p>

                    </div>
                    <div className='flex justify-center gap-x-4 flex-wrap mt-[35px] overflow-y-auto lg:h-[calc(100vh-160px)] px-4'>
                        {cardsList.map(data => <Card data={data} className={`lg:w-[370px] shadow-xl`} />)}
                    </div>
                </div>
                <div className='lg:min-w-[600px] w-[100vw] lg:w-[50%] h-[300px] lg:h-[100vh] relative mt-8 lg:mt-0'>
                    <Map />
                </div>
            </div>
            <div className='py-4'>
                <div className='max-w-[1300px] mx-auto text-center my-14 text-theme_grey2 text-[18px] leading-[24px] tracking-wider px-8'>

                    The data relating to real estate for sale on this website comes in part from the Miami Association of Realtors, Realtor Association of Greater Ft. Lauderdale, and the South Broward Board of Realtors. Information is deemed reliable but not guaranteed. Copyright © 2024, Miami Association of Realtors, Realtor Association of Greater Ft. Lauderdale, and the South Broward Board of Realtors. All rights reserved. The information being provided is for consumers' personal, non-commercial use and may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. The use of search facilities of data on the site, other than a consumer looking to purchase real estate, is prohibited.
                </div>
                <div className='flex justify-center gap-6 md:gap-12 flex-wrap items-center' >
                    <img alt='' src={miamiLogo} className='max-w-[300px]' />
                    <img alt='' src={sec3logo} className='max-w-[300px]' />
                </div>
            </div>

            <div className='py-4 mt-10'>
                <LuxuryListingsSlider />
            </div>
            <Footer />

        </div>
    )
}

export default InvestmentMiami