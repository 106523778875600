import React from 'react'
import exp1 from '../../../../assets/images/exp-owner.png'
import exp2 from '../../../../assets/images/exp-maria.png'
import exp3 from '../../../../assets/images/exp-hammad.png'
import Button from '../../../buttons/Button'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi'
import { HiMiniArrowLongRight } from 'react-icons/hi2'
import CommonCarousel from '../../../carousel/Carousel'
import AnimationDiv from '../../../animation/AnimationDiv'
import { Link } from 'react-router-dom'
const data = [
    {
        imgSrc: exp1,
        name: 'Daniel Pansky',
        description:
            `  With over 20 years of experience, Daniel specializes in luxury
                                properties and has a keen eye for detail. His commitment to excellence
                                has earned him numerous accolades in the industry.`,
    },
    {
        imgSrc: exp2,
        name: 'Maria Kuzina',
        description:
            ` An expert in residential real estate, Maria has helped countless
                                families find their dream homes. Her friendly demeanor and extensive
                                knowledge make her a favorite among clients.`,
    },
    {
        imgSrc: exp3,
        name: 'Hamad Fakhar',
        description:
            ` With over 8 years of experience, Hamad is a top expert in digital
                                marketing for luxury properties, brand & media management. His skill in
                                creating effective online strategies and media campaigns has earned him
                                many industry awards and helped numerous clients achieve their marketing
                                goals.`,
    },
];

const MeetExpertSec = () => {
    return (
        <div className='w-full pt-[70px]  '>

            <div className='mx-auto lg:w-[95%] max-w-[1727px]'>
                <div className='flex flex-col md:flex-row justify-between mx-5 md:mx-0  items-center'>
                    <AnimationDiv
                        initial={{ opacity: 0, x: -300 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 1 }}
                        viewport={{ once: true }}
                    >

                        <div className='lg:max-w-[442px] xl:max-w-[542px] '>
                            <h1 className='text-[25px] md:text-[35px] xl:text-[45px] text-theme_yellow poppins-semibold md:leading-[55.5px] leading-[30px]'>
                                Meet Our Expert Real Estate Agents
                            </h1>
                        </div>
                    </AnimationDiv>
                    <AnimationDiv
                        initial={{ opacity: 0, x: 300 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 1 }}
                        viewport={{ once: true }}
                    >

                        <div className='lg:max-w-[550px] xl:max-w-[710px]'>
                            <p className='poppins-regular text-theme_grey mt-2 md:mt-0 md:text-theme_yellow text-[16px] md:text-[25px] md:leading-[30px] '>
                                Professional and Dedicated Agents Committed to Helping You Find Your Ideal Property
                            </p>
                        </div>
                    </AnimationDiv>
                </div>
                <div className='flex flex-wrap mt-[70px] gap-3 max-w-[1600px] justify-between mx-auto'>

                    <CommonCarousel data={data} />
                </div>

                <div className='flex justify-center mt-[22px] md:mt-[52px] '>
                    <Link className='' to={'/contact'} >
                        <Button title={<p className='flex gap-[10px]  items-center justify-center poppins-semibold'><span className='text-[25px] hidden md:block'>Contact us!</span> <span className='md:hidden text-[16px]'>Contact me!</span> <span><HiMiniArrowLongRight className='text-[28px]' /></span></p>} className={'rounded-full poppins-semibold px-8 py-3 text-center'} />
                    </Link></div>
            </div>
        </div>
    )
}

export default MeetExpertSec