import React from 'react'
import ContactLayout from '../components/ui/layout/ContactLayout'

const Contact = () => {
    return (
        <>
            <ContactLayout />
        </>
    )
}

export default Contact