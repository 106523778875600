

import React from 'react'
import sec5Logo from '../../../../assets/images/sec5_logo.svg'
import card1 from '../../../../assets/images/card1.png'
import card2 from '../../../../assets/images/card2.png'
import card3 from '../../../../assets/images/card3.png'
import Card from '../../../cards/Card'
import Button from '../../../buttons/Button'
import AnimationDiv from '../../../animation/AnimationDiv'

export const cardsList = [
    {
        src: card1,
        name: 'Urban Elegance Apartment',
        loc: 'Downtown Cityscape',
        bed: '2',
        bath: '2',
        price: '$450,000'
    },
    {
        src: card2,
        name: 'Coastal Villa Paradise',
        loc: 'Sunny Beachfront',
        bed: '4',
        bath: '4',
        price: '$1,250,000'
    },
    {
        src: card3,
        name: 'Countryside Estate',
        loc: 'Green Meadows',
        bed: '5',
        bath: '4',
        price: '$850,000'
    },
    {
        src: card1,
        name: 'Urban Elegance Apartment',
        loc: 'Downtown Cityscape',
        bed: '2',
        bath: '2',
        price: '$450,000'
    },
    {
        src: card2,
        name: 'Coastal Villa Paradise',
        loc: 'Sunny Beachfront',
        bed: '4',
        bath: '4',
        price: '$1,250,000'
    },
    {
        src: card3,
        name: 'Countryside Estate',
        loc: 'Green Meadows',
        bed: '5',
        bath: '4',
        price: '$850,000'
    },
    {
        src: card1,
        name: 'Urban Elegance Apartment',
        loc: 'Downtown Cityscape',
        bed: '2',
        bath: '2',
        price: '$450,000'
    },
    {
        src: card2,
        name: 'Coastal Villa Paradise',
        loc: 'Sunny Beachfront',
        bed: '4',
        bath: '4',
        price: '$1,250,000'
    },
    {
        src: card3,
        name: 'Countryside Estate',
        loc: 'Green Meadows',
        bed: '5',
        bath: '4',
        price: '$850,000'
    },
    {
        src: card1,
        name: 'Urban Elegance Apartment',
        loc: 'Downtown Cityscape',
        bed: '2',
        bath: '2',
        price: '$450,000'
    },
    {
        src: card2,
        name: 'Coastal Villa Paradise',
        loc: 'Sunny Beachfront',
        bed: '4',
        bath: '4',
        price: '$1,250,000'
    },
    {
        src: card3,
        name: 'Countryside Estate',
        loc: 'Green Meadows',
        bed: '5',
        bath: '4',
        price: '$850,000'
    },
]
const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const BlueBgCardView = ({ children, title }) => {
    return (
        <div className='mt-24 min-h-96 bg-theme_blue md:p-10  md:rounded-tr-[250px] md:rounded-bl-[250px] rounded-tr-[220px] rounded-bl-[220px] md:sec_5_bg '>
            <div className='py-8 pb-24 md:px-4 mx-[6%]'>
                <div className='flex justify-between flex-wrap-reverse mx-[3%]'>
                    <div>
                        <AnimationDiv
                            initial={{ opacity: 0, x: -20 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}
                            viewport={{ once: true }}
                        >
                            <h1 className='text-[32px] md:text-[55px] lg:text-[4vw] leading-[30px] md:leading-[62px] lg:leading-[82px] text-theme_yellow font-bold my-4 creato-f-b'>
                                Our Exclusive {title} <br className='hidden md:block' />
                                OF THE MONTH!!!
                            </h1>
                        </AnimationDiv>
                        <p className='text-white text-[16px] leading-[27px] md:text-[18px] creato-f-r md:mx-3 max-w-[774px]'>
                            Handpicked properties tailored to your preferences. From modern urban apartments to breathtaking beachfront villas
                        </p>
                    </div>
                    <div>
                        <AnimationDiv
                            initial={{ opacity: 0, x: 20 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}
                            viewport={{ once: true }}
                        >
                            <img src={sec5Logo} alt="" className='max-w-[100px] md:max-w-full' />
                        </AnimationDiv>
                    </div>
                </div>

                <div className='lg:mt-20 mt-10 grid lg:grid-cols-2 xl:grid-cols-3 md:gap-[44px] max-w-[90%] mx-auto flex-wrap justify-center'>



                    {cardsList.map(v => <AnimationDiv
                        key={v.name}
                        className="lg:w-[100%]"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.2 }}
                        variants={cardVariants}
                    > <Card data={v} key={v.name} className={`lg:w-[100%] min-w-[300px]`} /> </AnimationDiv>)}

                </div>

                {children}

            </div>

        </div>
    )
}

export default BlueBgCardView