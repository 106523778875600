import React from 'react'
import Footer from '../components/ui/sections/FooterSection/Footer'
import Header from '../components/ui/header/Header'
import ContactUs from '../components/ui/sections/ContactUsSection/ContactUs'

const Subscribe = () => {
    return (
        <>
            <div className=' bg-theme_blue'>
                <Header />
            </div>
            <div className='flex flex-col-reverse'>
                <ContactUs className={'mt-0'} />
            </div>
            <Footer className={'mt-0'} />
        </>
    )
}

export default Subscribe