import React, { useState } from 'react';
import { AiFillMessage } from "react-icons/ai";
import { FaMinus } from "react-icons/fa6";
import chatUser from '../../assets/images/agent-card.svg'
import ButtonChatBox from '../buttons/ButtonChatBox';
import { MdHome } from "react-icons/md";
import { PiWechatLogo } from "react-icons/pi";
import { IoSend } from 'react-icons/io5';
import { FaLongArrowAltLeft } from 'react-icons/fa';
import { HiDotsHorizontal } from 'react-icons/hi';
import AnimationDiv from '../animation/AnimationDiv'
const ChatWidget = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isActiveChat, setIsActiveChat] = useState(false)

    const toggleChat = () => {
        setIsOpen(!isOpen);
    };
    const toggleStartChat = () => {
        setIsActiveChat(!isActiveChat);
    };

    return (
        <div className={`fixed ${isOpen ? 'bottom-[50px] ' : 'bottom-[160px]'} right-[20px] z-50 `}>
            {isOpen && (
                <AnimationDiv
                    initial={{ x: '100%' }} // Start off-screen to the right
                    animate={{ x: '0%' }} // Slide in to the original position
                    transition={{ type: 'spring', stiffness: 260, damping: 20, duration: 1, delay: 0 }}
                >
                    <div
                        className={`mt-2 rounded-[26px] bg-white w-[320px] sm:w-[379px]   shadow-lg transition-all duration-300 ease-in-out transform ${isOpen ? 'scale-100 opacity-100' : 'scale-95 opacity-0'
                            }`}
                    >
                        {isActiveChat ? (<>
                            <AnimationDiv
                                initial={{ opacity: 0, y: 10 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.3 }}
                                viewport={{ once: true }}
                            >
                                <div className=" flex flex-col  mb-2 p-0 text-theme_blue">
                                    <div className='flex  w-full items-cemter shadow-md p-4 pb-2'>

                                        <button onClick={toggleStartChat} className="text-gray-600 hover:text-gray-900">
                                            <FaLongArrowAltLeft className='text-xl' />
                                        </button>
                                        <button className="text-gray-600 hover:text-gray-900 ml-6"  >
                                            <HiDotsHorizontal className='text-xl' />
                                        </button>
                                        <div className='flex gap-2 items-center ml-10'>
                                            <div className='w-12 h-12 rounded-full relative'>
                                                <img src={chatUser} alt="" className='w-12 h-12 ' />
                                            </div>
                                            <div className='flex items-center flex-1'>
                                                <p className='text-xs text-nowrap creato-f-b'>Daniel Pansky</p>
                                            </div>

                                        </div>
                                        <button onClick={toggleChat} className="text-gray-600 hover:text-gray-900  ml-20">
                                            <FaMinus className='text-xl' />
                                        </button>
                                    </div>


                                </div>
                                <div className='py-4 text-theme_blue'>
                                    <div className="flex mx-10 my-4 py-4  px-6 pl-0 pt-0 mt-0 rounded-lg flex-col">
                                        <div className='flex items-center'>
                                            <div className='w-16 h-16 rounded-full relative'>
                                                <img src={chatUser} alt="" className='w-12 h-12 ' />
                                                <div className='w-3 h-3 bg-green-600 rounded-full absolute top-1 left-0'></div>
                                            </div>
                                            <div>
                                                <p className='text-sm creato-f-b'>Chat With Daniel</p>
                                            </div>

                                        </div>

                                    </div>

                                    <div className='mt-4 px-6 border-[#c4c4c4] border-[0.5px] max-w-[300px] shadow-xl py-4 rounded-xl mx-auto'>
                                        <div className=" text-center "><p className='text-[12px] creato-f-b tracking-wider px-6'>
                                            Please fill in the information below, and you will be connected to Daniel Pansky
                                        </p></div>

                                        <div className="cursor-pointer  mt-8">
                                            <p className='text-xs pl-1 creato-f-r'>First Name</p>
                                            <input type="text" className='focus:outline-none border-[0.5px] border-theme_blue rounded-md py-1 px-2 w-full' />
                                        </div>
                                        <div className="cursor-pointer mt-8">
                                            <p className='text-xs pl-1 creato-f-r'>Last Name</p>
                                            <input type="text" className='focus:outline-none border-[0.5px] border-theme_blue rounded-md py-1 px-2 w-full' />
                                        </div>
                                        <div className="cursor-pointer mt-8">
                                            <p className='text-xs pl-1 creato-f-r'>Email Address</p>
                                            <input type="text" className='focus:outline-none border-[0.5px] border-theme_blue rounded-md py-1 px-2 w-full' />
                                        </div>
                                        <div>
                                            <ButtonChatBox title={'Start the chat'} className={'w-full rounded-lg mt-12 font-semibold tracking-wider mb-4'} />
                                        </div>
                                    </div>

                                </div>
                            </AnimationDiv>
                        </>) : (<>
                            <AnimationDiv
                                initial={{ opacity: 0, y: 10 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.3 }}
                                viewport={{ once: true }}
                                key={'2'}
                            >
                                <div className=" flex flex-col  mb-2 chat-top-grad p-6 ">
                                    <div className='flex justify-end w-full items-start'>
                                        <button onClick={toggleChat} className="text-gray-600 hover:text-gray-900">
                                            <FaMinus className='text-xl' />
                                        </button>
                                    </div>
                                    <div className='flex-1 flex items-center justify-center text-center w-full mx-auto'>
                                        <p className='text-[28px] text-theme_blue font-extrabold'> Live Chat With Daniel
                                        </p>
                                    </div>

                                </div>
                                <div className='py-4'>
                                    <div className="flex mx-10 my-4 py-4 shadow-xl px-6 rounded-lg flex-col">
                                        <div className='flex items-center'>
                                            <div className='w-16 h-16 rounded-full relative'>
                                                <img src={chatUser} alt="" className='w-12 h-12 ' />
                                                <div className='w-3 h-3 bg-green-600 rounded-full absolute top-1 left-0'></div>
                                            </div>
                                            <div>
                                                <p className='text-xs creato-f-r'>Daniel Pansky. 7:40</p>
                                                <p className='text-base creato-f-b'>Chat With Daniel</p>
                                            </div>

                                        </div>
                                        <div className=''>
                                            <ButtonChatBox onClick={toggleStartChat} title={<div className='flex items-center gap-4 justify-center'><p className='font-extrabold tracking-wider'>Chat Now</p> <p><IoSend className='text-theme_blue' /></p> </div>} className={'px-10 py-2 rounded-lg  w-full'} />
                                        </div>
                                    </div>

                                    <div className='mt-32 flex justify-between px-8 w-[250px] shadow-xl py-4 rounded-lg mx-auto'>
                                        <div className="text-4xl cursor-pointer"><MdHome /></div>
                                        <div className="text-4xl cursor-pointer"><PiWechatLogo /></div>
                                    </div>

                                </div>
                            </AnimationDiv>

                        </>)}
                    </div>
                </AnimationDiv>
            )}
            {!isOpen && (
                <div className="mr-36 cursor-pointer" onClick={toggleChat}>
                    <div id="fc-phone" className="cb-button call_back">
                        <div className="cbb-circle"></div>
                        <div className="cbb-circle-fill"></div>
                        <div className="cbb-circle-img flex items-center justify-center text-white">
                            <AiFillMessage className="text-4xl ring-animate" />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChatWidget;
