import React from 'react';

const ImageViewer = ({ images }) => {
    return (
        <div className="container mx-auto p-4">
            <div className="grid grid-cols-1 md:grid-cols-7 gap-y-4 md:gap-4">
                {images.length > 0 && (
                    <div className="md:col-span-4 row-span-2 relative">
                        <img src={images[0]} alt="Main image" className="w-full h-full object-cover rounded-lg" />
                        <div className="absolute top-2 right-2 bg-yellow-500 text-white px-2 py-1 text-sm font-bold rounded">
                            FOR SALE
                        </div>
                    </div>
                )}
                <div className='md:grid md:grid-cols-2 col-span-3 row-span-2 gap-4 flex overflow-x-auto '>
                    {images.slice(1).map((image, index) => (
                        <div key={index} className={""}>
                            <img src={image} alt={`Image ${index + 2}`} className="w-full h-full object-cover rounded-lg" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ImageViewer;



// import React from 'react';

// const ImageViewer = ({ images }) => {
//   if (!images || images.length === 0) return null;

//   return (
//     <div className="container mx-auto p-4">
//       <div className="space-y-4">
//         <div className="relative rounded-lg overflow-hidden">
//           <img src={images[0]} alt="Main image" className="w-full h-auto object-cover" />
//           <div className="absolute top-2 right-2 bg-yellow-500 text-white px-2 py-1 text-sm font-bold rounded">
//             FOR SALE
//           </div>
//         </div>
//         <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
//           {images.slice(1).map((image, index) => (
//             <div key={index} className="rounded-lg overflow-hidden">
//               <img src={image} alt={`Image ${index + 2}`} className="w-full h-full object-cover" />
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ImageViewer;