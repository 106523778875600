import React from 'react'
import ContactLeft from '../../../../assets/images/contatct_bg_left.png'
import owner from '../../../../assets/images/owner_contatct.png'
import AnimationDiv from '../../../animation/AnimationDiv'
import { cn } from '../../../../lib/utils'
const ContactUs = ({ className }) => {
    return (
        <>
            <div className={cn(`flex mt-24 flex-wrap flex-col lg:flex-row `, className)}>

                <div className='flex-1 hidden md:block relative bg-theme_yellow'>
                    <img src={ContactLeft} alt="" width={'100%'} height={'100%'} className='h-full' />
                    <div className='absolute my-auto top-[47%] right-[40px] '>
                        <h1 className='text-6xl font-sans font-extrabold  text-white'>Get in Touch.</h1>
                    </div>
                </div>
                <div className='flex-1 md:bg-theme_yellow contact_right_bg relative'>
                    <div className=' md:hidden text-start py-6 mt-12 mx-auto w-[90%]'>
                        <h1 className='text-[45px] md:text-6xl font-sans font-extrabold  text-white'>Get in Touch.</h1>
                    </div>
                    <div className='p-2 py-4 md:p-8 bg-white mx-auto w-[90%] md:w-[70%] lg:w-[90%] mt-11 mb-16 md:my-16 rounded-3xl '>
                        <div className=' w-full'>
                            <div className='flex w-full px-4 md:px-12 my-4'><input placeholder='Name *' className='focus:outline-none placeholder:text-gray-500 placeholder:opacity-80 tracking-widest border-gray-500 border p-4 w-full rounded-xl' /></div>
                            <div className='flex w-full px-4 md:px-12 my-4'><input placeholder='Email *' className='focus:outline-none placeholder:text-gray-500 placeholder:opacity-80 tracking-widest border-gray-500 border p-4 w-full rounded-xl' /></div>
                            <div className='flex w-full px-4 md:px-12 my-4'><input placeholder='Phone *' className='focus:outline-none placeholder:text-gray-500 placeholder:opacity-80 tracking-widest border-gray-500 border p-4 w-full rounded-xl' /></div>
                            <div className='flex w-full px-4 md:px-12 my-4'><input placeholder='Topic *' className='focus:outline-none placeholder:text-gray-500 placeholder:opacity-80 tracking-widest border-gray-500 border p-4 w-full rounded-xl' /></div>
                            <div className='flex w-full px-4 md:px-12 my-4'>
                                <textarea placeholder='Your Message *' className='focus:outline-none placeholder:text-gray-500 placeholder:opacity-80 tracking-widest border-gray-500 border p-4 w-full md:w-[60%] rounded-xl' rows={10}>  
                                </textarea></div>
                            <div className='flex w-full px-4 md:px-12 my-4 gap-1 '><input id='terms' placeholder='Topic *' type='checkbox' className='focus:outline-none placeholder:text-gray-500 tracking-widest border-gray-500 border p-4 align-top self-start rounded-xl' />
                                <label htmlFor='terms' className='text-xs md:w-[50%] text-[#022541] creato-f-l z-10 tracking-wide'>By providing LUXLIFE. your contact information, you acknowledge and agree to our <span className='underline cursor-pointer'>Privacy Policy</span> and consent to receiving marketing communications, including through automated calls, texts, and emails, some of which may use artificial or prerecorded voices. This consent isn’t necessary for purchasing any products or services and you may opt out at any time. To opt out from texts, you can reply, ‘stop’ at any time. To opt out from emails, you can click on the unsubscribe link in the emails. Message and data rates may apply.
                                </label></div>

                            <div className='flex w-full px-4 md:px-12 my-4 mt-[20px]'>
                                <button className={'btn-primary py-3 bg-theme_yellow text-white rounded-full text-nowrap px-14 font-extrabold tracking-widest'} style={{ fontSize: 14 }}>Submit</button>
                            </div>
                        </div>
                    </div>
                    <div className='absolute bottom-0 right-0 hidden 1630px:block '>
                        <AnimationDiv
                            initial={{ x: '100%' }} // Start off-screen to the right
                            whileInView={{ x: '0%' }} // Slide in to the original position
                            transition={{ type: 'spring', stiffness: 260, damping: 20, duration: 1, delay: 0.1 }}
                        >
                            <img src={owner} alt="" className='w-60 md:w-auto' />
                        </AnimationDiv>
                    </div>
                </div>

            </div>

            <div className='flex flex-col md:flex-row p-6 flex-wrap lg:pb-[46px] lg:pt-[60px] max-w-[1500px] mx-auto'>
                <div className='flex-1 flex items-center'>
                    <p className='creato-f-black text-[22px] max-w-[537px] md:text-[30px] leading-[30px] tracking-wide text-theme_blue'>
                        Top Real Estate Insights Delivered,
                        Straight to Your Inbox.
                    </p>
                </div>
                <div className='flex-1 mt-10'>
                    <div className='max-w-[594px] mx-auto'>
                        <div className=''><input placeholder='Enter Your Email *' className='placeholder:text-gray-500 placeholder:opacity-80 text-[19px] border border-gray-500 px-8 py-5 w-full rounded-xl placeholder:tracking-wider' /></div>
                        <div className='flex w-full my-4 mt-[26px] gap-1 '>
                            <input id='terms2' placeholder='Topic *' type='checkbox' className='focus:outline-none tracking-widest border-gray-200 border p-4 align-top self-start rounded-xl' />
                            <label htmlFor='terms2' className='text-xs text-[#022541] creato-f-l text-[15px] tracking-wider'>By providing LUXLIFE. your contact information, you acknowledge and agree to our <span className='underline cursor-pointer'>Privacy Policy</span> and consent to receiving marketing communications, including through automated calls, texts, and emails, some of which may use artificial or prerecorded voices. This consent isn’t necessary for purchasing any products or services and you may opt out at any time. To opt out from texts, you can reply, ‘stop’ at any time. To opt out from emails, you can click on the unsubscribe link in the emails. Message and data rates may apply.
                            </label></div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ContactUs