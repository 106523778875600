import React from 'react';
import Header from '../components/ui/header/Header';
import sec_3_1 from '../assets/images/sec_3_1.png';
import sec_3_2 from '../assets/images/sec_3_2.png';
import sec_3_3 from '../assets/images/sec_3_3.png';
import sec_3_4 from '../assets/images/sec_3_4.png';
import { BsArrowUpRight } from 'react-icons/bs';
import { CiLocationOn } from 'react-icons/ci';
import hero from '../assets/images/sell_property_hero.png'
import sec5logo from '../assets/images/singlePreConstruction.svg'
import Button from '../components/buttons/Button';
import { IoIosArrowForward, IoIosShareAlt } from 'react-icons/io';
import { LuDownload } from 'react-icons/lu';
import { AiFillPicture } from 'react-icons/ai';
import { FaLocationDot } from 'react-icons/fa6';
import bed from '../assets/images/bed.png'
import bath from '../assets/images/bath.png'
import area from '../assets/images/area.png'
import { HiOutlineBuildingOffice2 } from 'react-icons/hi2';
import { FaChevronLeft, FaChevronRight, FaDollarSign, FaShower } from 'react-icons/fa';
import { IoBedSharp } from 'react-icons/io5';
import { PiCurrencyDollarBold, PiGreaterThan, PiLessThan } from 'react-icons/pi';
import { BiChevronRight } from 'react-icons/bi';
// Sample data array with card details
const cardData = [
    {
        id: 1,
        title: 'Luxury Apartments',
        location: 'Miami',
        image: sec_3_1,
    },
    {
        id: 2,
        title: 'Modern Condos',
        location: 'New York',
        image: sec_3_2,
    },
    {
        id: 3,
        title: 'Beachfront Villas',
        location: 'Los Angeles',
        image: sec_3_3,
    },
    {
        id: 4,
        title: 'Urban Lofts',
        location: 'Chicago',
        image: sec_3_4,
    },
];
const residenceData = [
    { id: 1, residence: '181 East 101st Street PH9', beds: 3, baths: 3, halfBaths: 0, price: 1295000, sqft: 972, status: 'FOR SALE' },
    { id: 2, residence: '181 E 101st Street Unit: 202', beds: 1, baths: 1, halfBaths: 0, price: 949000, sqft: 842, status: 'FOR SALE' },
    { id: 3, residence: '181 East 101st Street Unit: 803', beds: 2, baths: 2, halfBaths: 0, price: 949000, sqft: 726, status: 'FOR SALE' },
    { id: 4, residence: '181 East 101st Street PH1', beds: 4, baths: 4, halfBaths: 0, price: 899000, sqft: 650, status: 'FOR SALE' },
    { id: 5, residence: '181 East 101st Street 802', beds: 1, baths: 1, halfBaths: 0, price: 649000, sqft: 483, status: 'FOR SALE' },
];

const soldData = [
    { id: 6, residence: '181 East 101st Street PH9', beds: 3, baths: 3, halfBaths: 0, price: 1295000, sqft: 972, status: 'SOLD' },
    { id: 7, residence: '181 E 101st Street Unit: 202', beds: 1, baths: 1, halfBaths: 0, price: 949000, sqft: 842, status: 'SOLD' },
    { id: 8, residence: '181 East 101st Street Unit: 803', beds: 2, baths: 2, halfBaths: 0, price: 949000, sqft: 726, status: 'SOLD' },
    { id: 9, residence: '181 East 101st Street PH1', beds: 4, baths: 4, halfBaths: 0, price: 899000, sqft: 650, status: 'SOLD' },
    { id: 10, residence: '181 East 101st Street 802', beds: 1, baths: 1, halfBaths: 0, price: 649000, sqft: 483, status: 'SOLD' },
];

const ResidenceTable = ({ title, data }) => (
    <div className="my-8 creato-f-b  text-theme_blue">
        <h2 className="text-3xl creato-f-b  mb-4">{title}</h2>
        <table className="w-full border-collapse lg:table hidden">
            <thead>
                <tr className="text-left text-sm text-gray-600 border-b">
                    <th className="py-2 font-semibold">RESIDENCE</th>
                    <th className="py-2 font-semibold">BEDS</th>
                    <th className="py-2 font-semibold">BATHS</th>
                    <th className="py-2 font-semibold">HALF BATHS</th>
                    <th className="py-2 font-semibold">PRICE</th>
                    <th className="py-2 font-semibold">SQFT</th>
                    <th className="py-2 font-semibold">STATUS</th>
                    <th className="py-2"></th>
                </tr>
            </thead>
            <tbody>
                {data.map((item) => (
                    <tr key={item.id} className="border-b last:border-b-0 hover:bg-gray-50 cursor-pointer ">
                        <td className="py-4 pl-2 ">{item.residence}</td>
                        <td className="py-4">{item.beds}</td>
                        <td className="py-4">{item.baths}</td>
                        <td className="py-4">{item.halfBaths}</td>
                        <td className="py-4">${item.price.toLocaleString()}</td>
                        <td className="py-4">{item.sqft}</td>
                        <td className="py-4">
                            <span className={`px-2 py-1 text-xs font-semibold rounded ${item.status === 'FOR SALE' ? 'bg-theme_blue text-white' : 'bg-gray-300 text-white'
                                }`}>
                                {item.status}
                            </span>
                        </td>
                        <td className="py-4">
                            <BiChevronRight className="text-gray-400" />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        <div className="mb-8">
            <table className="w-full border-collapse text-theme_blue text-[12px] lg:hidden">
                <thead>
                    <tr className="lg:text-left text-sm text-center  border-b ">
                        <th className="py-2 font-semibold">RESIDENCE</th>
                        <th className="py-2 font-semibold">BEDS\BATHS\HALF BATHS</th>
                        <th className="py-2 font-semibold">PRICE\SQFT</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={item.id} className="border-b last:border-b-0 cursor-pointer">
                            <td className="py-4 text-center  text-navy-700">{item.residence}</td>
                            <td className="py-4 text-center ">{`${item.beds}\\${item.baths}\\${item.halfBaths}`}</td>
                            <td className="py-4 text-center ">{`$${item.price.toLocaleString()}\\${item.sqft}`}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
);

const SinglePreContruction = () => {
    return (
        <>

            <div className="relative w-full min-h-[500px]  md:h-[95vh] overflow-hidden">
                <div className='fixed top-0 z-[10000] w-full'>
                    <Header />
                </div>
                {/* Image with full width and object cover to maintain aspect ratio */}
                <img src={sec5logo} alt="Hero Image" className=" w-full absolute h-full object-cover" />

                {/* Overlay with semi-transparent black background */}
                <div className="absolute inset-0 gradient-background "></div>

                {/* Centered text block */}
                <div className="absolute bottom-[10%] md:left-6   text-white px-4 md:px-8 w-full ">
                    <div className="">

                        <p className='text-[6vw] md:text-[4vw]'>
                            Mercedes-Benz Places - Miami

                        </p>
                        <p className="text-[3vw] md:text-[2vw]  lg:text-[1.2vw] creato-f-m tracking-wide mx-auto">
                            1133 SW 2nd Ave, Miami, FL 33130
                        </p>
                    </div>
                    <div className='flex justify-center mx-auto md:justify-end items-center mt-14  mr-[2%] gap-2 lg:gap-4'>
                        <Button title={<div className='flex items-center gap-2'><span><LuDownload className='text-[16px] md:text-[20px]' /></span><div>
                            <p className='text-[9px]'>Download</p>
                            <p className='text-[8px] lg:text-[12px]'>Floor Plans</p>
                        </div></div>} className={'bg-white px-2 lg:px-4 text-theme_blue rounded-xl py-3 creato-f-m  tracking-wide btn2'} />
                        <Button title={<div className='flex items-center gap-2'><span><LuDownload className='text-[16px] md:text-[20px]' /></span><div>
                            <p className='text-[9px]'>Download</p>
                            <p className='text-[8px] lg:text-[12px]'>Brochure</p>
                        </div></div>} className={'bg-white px-2 lg:px-4 text-theme_blue rounded-xl py-3 creato-f-m  tracking-wide btn2'} />
                        <div className='flex gap-2 lg:gap-4'>
                            <div className={`p-2 lg:p-3 md:text-lg rounded-full flex justify-center items-center   bg-white text-black`}><AiFillPicture /></div>
                            <div className={`p-2 lg:p-3 md:text-lg rounded-full flex justify-center items-center   bg-white text-black`}><FaLocationDot /></div>
                            <div className={`p-2 lg:p-3 md:text-lg rounded-full flex justify-center items-center   bg-white text-black`}><IoIosShareAlt /></div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="  mx-auto  rounded-t-xl relative">
                <div className=' md:mt-[-62px] w-full rounded-t-[70px] bg-white p-4 md:p-12'>

                    <div className=" container max-w-[1400px] mx-auto p-6 ">
                        {/* Header with property details */}
                        <div className="flex flex-col md:flex-row justify-between md:items-center mb-6  rounded-lg  border  text-theme_blue">
                            <div className="flex justify-between flex-1 items-center space-x-4">
                                <div className="flex-1 py-4 border-r p-4 items-center">
                                    <IoBedSharp className='text-[30px] mb-1' />
                                    <span className="creato-f-black text-[3vw] md:text-[2vw] ">0 - 3 </span>
                                    <p className='creato-f-b text-[14px]'>BEDS</p>
                                </div>
                                <div className="flex-1 py-4 border-r p-4">
                                    <FaShower className='text-[30px] mb-1' />
                                    <span className=" creato-f-black text-[3vw] md:text-[2vw] ">1.0 - 2.0 </span>
                                    <p className='creato-f-b text-[14px]'>BATHS</p>
                                </div>
                                <div className="flex-1 py-4 border-r p-4">
                                    <HiOutlineBuildingOffice2 className='text-[30px] mb-1' />
                                    <span className=" creato-f-black text-[3vw] md:text-[2vw] ">791 </span>
                                    <p className='creato-f-b text-[14px]'>Residences</p>
                                </div>
                            </div>
                            <div className='flex-1 py-4 border-r p-4 border-t md:border-t-none '>
                                <PiCurrencyDollarBold className='text-[30px] mb-1' />
                                <span className="creato-f-black text-[3vw] md:text-[2vw] ">$ 1.23 M to $ 2.73 M</span>
                                <p className="creato-f-b text-[14px]">Price Range</p>
                            </div>
                        </div>

                        {/* Main content */}
                        <div className="flex flex-col justify-center md:flex-row gap-6 lg:gap-16 mt-16 text-theme_blue">
                            {/* Left column with image and title */}
                            <div className="md:w-1/2 max-w-[500px] ">
                                <h2 className="creato-f-black text-[2.4] md:text-[1.6vw] mb-6">Timeless design, inspired by Miami.</h2>
                                <img
                                    src={sec5logo}
                                    alt="Mercedes-Benz Places Building"
                                    className="w-full rounded-lg shadow-md object-cover max-w-[500px] h-[600px]"
                                />
                            </div>

                            {/* Right column with description and features */}
                            <div className="md:w-1/2 pt- creato-f-m mb-8 md:text-[20px]">
                                <p className="">
                                    Mercedes-Benz' first residential project in North America weaves mobility solutions
                                    with unparalleled standards of innovative living to create a visionary community.
                                    Centrally located in Brickell, Miami, Mercedes-Benz Places is being developed by
                                    JDS Development Group. The Mercedes-Benz design team has worked in
                                    collaboration with award-winning SHoP Architects in collaboration with ODP,
                                    interiors by Woods Bagot, and landscaping by Field Operations. With luxurious
                                    condominium residences, office and wellness spaces, a world-class hotel, and newly
                                    -built open park by Field Operations, the project establishes a one-of-a-kind, future-
                                    oriented experience. Welcome to the distinctive urban oasis that combines
                                    sophistication, convenience, and sustainable living in one extraordinary location.
                                </p>

                                <p className="mb-4 mt-8">
                                    Inquire at www.miami.mercedesbenzplaces.com
                                </p>

                                <h3 className=" text-[25px] my-6">Features</h3>
                                <ul className="list-disc flex  gap-12 flex-wrap   mb-4">
                                    <li className=' max-w-[280px] flex-1'>24/7 Concierge And Valet</li>
                                    <li className=' max-w-[280px] flex-1'>Exclusive Rooftop Pool For Residents</li>

                                </ul>
                                <ul className="list-disc flex  gap-12 flex-wrap   mb-4">
                                    <li className=' max-w-[280px] flex-1'>Custom Mercedes-Benz Race Car Simulator</li>
                                    <li className=' max-w-[280px] flex-1'>EV Charging Stations</li>
                                </ul>

                                <Button title={<div className='flex items-center gap-4'><span>Visit Building Website</span> <span><IoIosArrowForward className='text-[20px]' /></span></div>} className={'bg-theme_yellow px-8 text-white rounded-full py-3 creato-f-m  tracking-wide '} />

                            </div>
                        </div>
                    </div>


                </div>
                <div className='bg-[#FEE8E8] mt-10'>
                    <div className='max-w-[1400px] px-4 py-10 mx-auto'>
                        <ResidenceTable title="Available Residences" data={residenceData} />
                        <ResidenceTable title="Sold or Leased Residences" data={soldData} />

                        <div className='flex mt-12 justify-center gap-4 '>

                            <div className="p-4 rounded-full text-white hover:scale-[1.1] transition duration-300 cursor-pointer  bg-theme_blue"><FaChevronLeft className='text-[18px]' /></div>
                            <div className="p-4 rounded-full text-white hover:scale-[1.1] transition duration-300 cursor-pointer bg-theme_blue"><FaChevronRight className='text-[18px]' /></div>

                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default SinglePreContruction;
