import React, { useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import uploadImg from '../../../assets/images/upload-img.png'
import Button from '../../buttons/Button';
const { Dragger } = Upload;

const Uploader = () => {
    const [fileList, setFileList] = useState([]);

    const props = {
        name: 'file',
        multiple: true,
        action: '', // Set this to an empty string to prevent requests
        fileList, // Controlled file list state
        onChange(info) {
            const { file, fileList: newFileList } = info;
            // Update file list state
            setFileList(newFileList);

            // Display success message without actually uploading
            if (file.status === 'done') {
                message.success(`${file.name} file uploaded successfully.`);
            } else if (file.status === 'error') {
                message.error(`${file.name} file upload failed.`);
            }
        },
        onRemove(file) {
            // Handle file removal from the state
            setFileList((prev) => prev.filter((item) => item.uid !== file.uid));
        },
        beforeUpload(file) {
            // Prevent actual upload by returning false
            return false;
        },
        className: 'uploader-properties'
    };
    return (
        <Dragger {...props}>
            <div className='flex justify-center '>
                <div className="w-[200px] hidden md:flex h-[200px] bg-theme_blue text-theme_yellow  flex-col justify-center items-center rotate-[-10deg] border border-white rounded-lg">
                    <p className='text-[12px]'>Drag files here to upload.</p>
                    <img src={uploadImg} alt="" className='object-cover max-w-[100px] mt-4' />
                </div>
                <div className="w-[200px] h-[200px] bg-theme_blue text-theme_yellow flex flex-col justify-center items-center md:ml-[-20px] border border-white rounded-lg">
                    <img src={uploadImg} alt="" className='object-cover max-w-[100px] mt-4' />
                </div>
            </div>
            <div className='p-4'>
                <p className="ant-upload-text">or</p>
                <Button title={'Select files'} className={'py-2 px-8 rounded-full mt-4'} />
            </div>
        </Dragger>
    );
}
export default Uploader;