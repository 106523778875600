import React, { useEffect, useState } from 'react'
import ContactLayout from '../components/ui/layout/ContactLayout'
import { cardsList } from '../components/ui/sections/section5/Section5'
import AnimationDiv from '../components/animation/AnimationDiv'
import Card from '../components/cards/Card';
import ListingsUI from '../components/tabs/TabNew100listing';
import { Switch } from 'antd';
import { useParams } from 'react-router-dom';
export const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const tabs = [
    { tab: 'listing', label: '100 Newest Listings', content: 'This Luxlife list shows the 100 newest luxury listings in and around Miami. The condos and homes shown below are listed for $0 and higher. Perhaps this is the time to make that first and best offer!' },
    { tab: 'penthouses', label: '100 Newest Penthouses', content: 'This Luxlife list shows the 100 newest luxury listings in and around Miami. The condos and homes shown below are listed for $0 and higher. Perhaps this is the time to make that first and best offer!' },
    { tab: 'condos', label: '100 Newest Condos', content: 'This Luxlife list shows the 100 newest luxury listings in and around Miami. The condos and homes shown below are listed for $0 and higher. Perhaps this is the time to make that first and best offer!' },
    { tab: 'homes-100', label: '100 Newest Homes', content: 'This Luxlife list shows the 100 newest luxury listings in and around Miami. The condos and homes shown below are listed for $0 and higher. Perhaps this is the time to make that first and best offer!' },
    { tab: 'expensive', label: '100 Most Expensive', content: 'This Luxlife list shows the 100 newest luxury listings in and around Miami. The condos and homes shown below are listed for $0 and higher. Perhaps this is the time to make that first and best offer!' },
];


const New100Listing = () => {
    const { tab } = useParams();
    const [activeTab, setActiveTab] = useState(tabs.find(v => v.tab === tab) ?? tabs[0]);
    const [areas, setAreas] = useState([
        { name: 'Aventura', selected: false },
        { name: 'Bal Harbour', selected: false },
        { name: 'Bay Harbor Islands', selected: false },
        { name: 'Boca Raton', selected: false },
        { name: 'Coral Gables', selected: false },
        { name: 'Fisher Island', selected: false },
        { name: 'Golden Beach', selected: false },
        { name: 'Hollywood', selected: false },
        { name: 'North Miami', selected: false },
        { name: 'Pompano Beach', selected: false },
        { name: 'Sunny Isles Beach', selected: false },
        { name: 'Surfside', selected: false },
        { name: 'West Palm Beach', selected: false },
        { name: 'Key Biscayne', selected: false },
        { name: 'Indian Creek Village', selected: false },
        { name: 'Normandy Isles', selected: false },
        { name: 'North Bay Village', selected: false },
        { name: 'South Miami', selected: false },
        { name: 'Hallandale Beach', selected: false },
        { name: 'Pinecrest', selected: false },
        { name: 'Miami Beach', selected: false },
        { name: 'Fort Lauderdale', selected: false },
        { name: 'Miami Gardens', selected: false },
    ]);


    useEffect(() => {
        setActiveTab(tabs.find(v => v.tab === tab) ?? tabs[0])
    }, [tab])


    return (
        <ContactLayout>
            <div>
                <h1 className='text-[32px] md:text-[62px] creato-f-b text-center tracking-wide text-theme_blue mt-[75px]'>The Luxlife</h1>
            </div>
            <ListingsUI activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} areas={areas} setAreas={setAreas} />
            <div className='px-[2%] '>
                <div className='p-4 mt-14'>
                    <h1 className='text-theme_blue md:text-[45px] creato-f-b'>{activeTab?.label}</h1>
                    <div className='flex gap-4 my-2'>
                        <div className='flex items-center gap-2'>
                            <Switch /> <span className='text-theme_blue segoeui font-bold text-[20px]'>Home</span>
                        </div>
                        <div className='flex items-center gap-2 '>
                            <Switch /> <span className='text-theme_blue segoeui font-bold text-[20px]'>Condos</span>
                        </div>
                    </div>
                </div>
                <div className='flex justify-center flex-wrap gap-x-4'>
                    {[...cardsList, ...cardsList].map(v => <AnimationDiv
                        key={v.name}
                        className="lg:w-[420px]"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.2 }}
                        variants={cardVariants}
                    > <Card data={v} key={v.name} className={`lg:w-[420px] shadow-lg`} /> </AnimationDiv>)}
                </div>
                <div className='max-w-[1400px] segoeui text-[14px] leading-[19px] text-theme_grey2 text-center mx-auto py-10 px-4'>
                    The properties above are provided by Miami Association of Realtors, and represent properties found in Aventura, Miami Beach, Sunny Isles Beach, Bal Harbour, Surfside, Miami, Key Biscayne. Data updated 8/5/2024. Daniel Pansky is licensed with Luxlife as a cooperating broker or cooperating agent. The information above believed to be accurate, but is not guaranteed. The data for these properties come from the Internet Data Exchange (IDX), a collaboration between brokers and REALTOR® associations in which brokers allow their listings to be syndicated to websites hosted by cooperating REALTOR® member brokers. Brokers appearing on this website as the contact person for any property is not necessarily the listing agent. The information being provided is for consumers' personal, non-commercial use. Federal law prohibits discrimination on the basis of race, color, religion, sex, handicap, familial status or national origin in the sale, rental or financing of housing.
                </div>
            </div>
        </ContactLayout>
    )
}

export default New100Listing