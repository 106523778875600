import React from 'react'
import bed from '../../assets/images/bed.png'
import bath from '../../assets/images/bath.png'
import area from '../../assets/images/area.png'
import { FaLocationDot } from "react-icons/fa6";
import Button from '../buttons/Button';

const Card = ({ data, className }) => {
    return (
        <div className={`w-[350px] my-4  min-h-[450px] rounded-2xl overflow-hidden black_white_effect2 group cursor-pointer transition duration-300 ${className}`}>
            <div className="h-[250px] overflow-hidden">
                <img src={data.src} alt='' width={'100%'} className='h-[250px] transition duration-300 min-w-[350px]' style={{ objectFit: 'cover' }} />
            </div>
            <div className="bg-white p-6 rounded-b-2xl">
                <div className="md:text-[18px] xl:text-[1.2vw] flex justify-between	poppins-bold text-theme_yellow font-semibold text-start mb-2 leading-[27px] xl:leading-[1.6vw]">
                    <p>{data.name}
                    </p>
                    <p className='text-[12px] text-green-500 sm:hidden'>Active</p>
                </div>
                <div className="flex  flex-col justify-between gap-2 flex-wrap poppins-regular">

                    <div className='flex justify-between'>
                        <div className="flex items-center gap-2   ">
                            <img src={bed} alt='' className='w-5' /> <p className='text-[#101010]  text-[12px] text-nowrap leading-[18px]'>{data.bed} Bed</p>
                            <img src={bath} alt='' className='w-5' /> <p className='text-[#101010]  text-[12px] text-nowrap leading-[18px]'>{data.bath} Bath</p>
                            <img src={area} alt='' className='w-5' /> <p className='text-[#101010]  text-[12px] text-nowrap leading-[18px]'>{'800'} SQFT</p>
                        </div>
                        <div className='hidden sm:block'>
                            <p className='text-[12px] text-green-500'>Active</p>
                        </div>
                    </div>
                    <div className="flex items-center gap-1  " >
                        <FaLocationDot className='text-[13px]' /> <p className='text-[#101010] 	 text-[11px] text-nowrap leading-[18px]'>{data.loc}
                        </p>
                    </div>

                </div>

                <div className='flex mt-[22px] justify-between items-center'>
                    <p className='text-lg md:text-[24px]  poppins-bold '>{data.price}</p>
                    <div>
                        <Button title={'Book Now'} className={'py-[9px] max-w-[95px] text-[13px] rounded-xl poppins-semibold'} />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Card