import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import card1 from '../../assets/images/sec_3_1.png'
import card2 from '../../assets/images/sec_3_2.png'
import card3 from '../../assets/images/sec_3_3.png'
import bed from '../../assets/images/bed.png'
import bath from '../../assets/images/bath.png'
import sqfeetwhite from '../../assets/images/sqfeetwhite.svg'
import Button from '../buttons/Button';
import { IoBedSharp } from 'react-icons/io5';
import { FaChevronLeft, FaChevronRight, FaShower } from 'react-icons/fa';
import { IoIosArrowForward } from 'react-icons/io';




const listings = [
    { id: 1, title: "Luxury Condo", price: 450000, image: card1, address: "1500 5th Street Suite 254, Miami Beach, FL 33139", bed: 2, bath: 4, sqft: 6 },
    { id: 2, title: "Penthouse Suite", price: 450000, image: card2, address: "1500 5th Street Suite 254, Miami Beach, FL 33139", bed: 2, bath: 4, sqft: 6 },
    { id: 3, title: "Beachfront Tower", price: 450000, image: card3, address: "1500 5th Street Suite 254, Miami Beach, FL 33139", bed: 2, bath: 4, sqft: 6 },
    { id: 4, title: "Luxury Villa", price: 650000, image: card3, address: "2000 Ocean Drive, Miami Beach, FL 33139", bed: 3, bath: 5, sqft: 8 },
    { id: 5, title: "Downtown Loft", price: 320000, image: card2, address: "900 Biscayne Blvd, Miami, FL 33132", bed: 1, bath: 2, sqft: 4 },
];


const fadeVariants = {
    hidden: { opacity: 0.2 },
    visible: { opacity: 1 },
};

const LuxuryListingsSlider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % listings.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + listings.length) % listings.length);
    };

    const visibleListings = [
        listings[currentIndex],
        listings[(currentIndex + 1) % listings.length],
        listings[(currentIndex + 2) % listings.length]
    ];

    return (
        <div className='bg-theme_blue flex flex-col justify-center py-16 '  >
            <div className='p-4 flex mx-auto'>
                <div className='p-4 py-16 flex mx-auto gap-4'>
                    {/* Left side content */}
                    <div className="">
                        <div className="text-white max-w-[335px] md:max-w-[700px] text-center">
                            <h1 className='text-[35px] md:text-[60px] creato-f-m md:leading-[60px]'>Luxlife's</h1>
                            <h2 className='text-[25px]  md:text-[50px]'>Top 100</h2>
                            <p className='text-sm lg:text-[20px] tracking-wider text-wrap'>
                                Luxlife's Top 100 shows the 100 newest luxury listings in and around Miami. The condos and homes shown below are listed for $0 and higher. Perhaps this is the time to make that first and best offer!
                            </p>
                        </div>
                        {/* First two listings */}
                        <div className="flex gap-6 mt-10">
                            {visibleListings.slice(0, 2).map((listing, index) => (
                                <div key={index} className="relative dark-bottom-overlay text-white group overflow-hidden cursor-pointer">
                                    <img src={listing.image} alt="" className='min-w-[300px] w-[350px] h-[500px] object-cover group-hover:scale-[1.1] transition duration-500' />
                                    <div className='absolute left-2 z-30 bottom-4 pl-[10px]'>
                                        <Button className={'bg-theme_blue text-[14px] tracking-widest text-white px-8 rounded-full py-2 btn2'} title={'For Sale'} />
                                        <h2 className='poppins-bold md:text-[24px] mt-2'>$ {listing.price}</h2>
                                        <p className='text-[12px] leading-[15px]'>{listing.address}</p>
                                        <div className="flex items-center gap-2 text-[#fff] creato-f-r">
                                            <IoBedSharp className='text-[20px] mb-1' /><p className='text-[12px] text-nowrap leading-[18px]'>{listing.bed} Bed</p>
                                            <FaShower className='text-[30px] mb-1' /> <p className='text-[12px] text-nowrap leading-[18px]'>{listing.bath} Bath</p>
                                            <img src={sqfeetwhite} alt='' className='w-5' /> <p className='text-[12px] text-nowrap leading-[18px]'>{listing.sqft} SQFT</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Right side with animated image */}
                    <div className="lg:flex justify-end items-end hidden">
                        <AnimatePresence mode="wait">
                            <motion.div
                                key={currentIndex}
                                variants={fadeVariants}
                                initial="hidden"
                                animate="visible"
                                exit="hidden"
                                transition={{ duration: 0.3 }}
                                className="relative dark-bottom-overlay text-white group overflow-hidden cursor-pointer"
                            >
                                <img src={visibleListings[2].image} alt="" className='lg:w-[550px] h-[750px] object-cover group-hover:scale-[1.1] transition duration-500' />
                                <div className='absolute left-4 z-30 bottom-4 pl-[10px]'>
                                    <Button className={'bg-theme_blue text-[14px] tracking-widest text-white px-8 rounded-full py-2 btn2'} title={'For Sale'} />
                                    <h2 className='poppins-bold md:text-[24px] mt-2'>$ {visibleListings[2].price}</h2>
                                    <p className='text-[12px] leading-[15px]'>{visibleListings[2].address}</p>
                                    <div className="flex items-center gap-2 text-[#fff] creato-f-r">
                                        <IoBedSharp className='text-[20px] mb-1' /><p className='text-[12px] text-nowrap leading-[18px]'>{visibleListings[2].bed} Bed</p>
                                        <FaShower className='text-[30px] mb-1' /> <p className='text-[12px] text-nowrap leading-[18px]'>{visibleListings[2].bath} Bath</p>
                                        <img src={sqfeetwhite} alt='' className='w-5' /> <p className='text-[12px] text-nowrap leading-[18px]'>{visibleListings[2].sqft} SQFT</p>
                                    </div>
                                </div>
                            </motion.div>
                        </AnimatePresence>
                    </div>
                </div>
            </div>

            {/* Navigation buttons */}
            <div className='flex justify-between items-center max-w-[1330px] px-4 w-full mx-auto'>
                <div className='flex justify-center items-center gap-4'>
                    <div onClick={prevSlide} className="p-4 rounded-full text-white hover:scale-[1.1] border-white border transition duration-300 cursor-pointer bg-theme_blue">
                        <FaChevronLeft className='text-[18px]' />
                    </div>
                    <div onClick={nextSlide} className="p-4 rounded-full text-white hover:scale-[1.1] border-white border transition duration-300 cursor-pointer bg-theme_blue">
                        <FaChevronRight className='text-[18px]' />
                    </div>
                </div>
                <div className='flex justify-center'>
                    <Button
                        title={
                            <div className='flex items-center gap-4'>
                                <span>View more</span>
                                <span><IoIosArrowForward className='text-[20px]' /></span>
                            </div>
                        }
                        className={'bg-theme_yellow px-8 text-white rounded-full py-3 max-h-[50px] creato-f-m tracking-wide btn2'}
                    />
                </div>
            </div>
        </div>
    );
};

export default LuxuryListingsSlider;