import React from 'react';
import ContactLayout from '../components/ui/layout/ContactLayout';
import sec_3_1 from '../assets/images/sec_3_1.png';
import sec_3_2 from '../assets/images/sec_3_2.png';
import sec_3_3 from '../assets/images/sec_3_3.png';
import sec_3_4 from '../assets/images/sec_3_4.png';
import { BsArrowUpRight } from 'react-icons/bs';
import { CiLocationOn } from 'react-icons/ci';
import hero from '../assets/images/sell_property_hero.png'
import sec5logo from '../assets/images/hero_bg.jpg'
import Button from '../components/buttons/Button';
import { IoIosArrowForward } from 'react-icons/io';
// Sample data array with card details
const cardData = [
    {
        id: 1,
        title: 'Luxury Apartments',
        location: 'Miami',
        image: sec_3_1,
    },
    {
        id: 2,
        title: 'Modern Condos',
        location: 'New York',
        image: sec_3_2,
    },
    {
        id: 3,
        title: 'Beachfront Villas',
        location: 'Los Angeles',
        image: sec_3_3,
    },
    {
        id: 4,
        title: 'Urban Lofts',
        location: 'Chicago',
        image: sec_3_4,
    },
];

const PreConstruct = () => {
    return (
        <ContactLayout>

            <div className="relative w-full max-h-[500px] md:max-h-[600px] min-h-[300px]  lg:max-h-[700px]">
                {/* Image with full width and object cover to maintain aspect ratio */}
                <img src={sec5logo} alt="Hero Image" className="w-full h-full max-h-[500px] md:max-h-[600px] min-h-[300px]  lg:max-h-[700px]   object-cover" />

                {/* Overlay with semi-transparent black background */}
                <div className="absolute inset-0 bg-black opacity-50 "></div>

                {/* Centered text block */}
                <div className="absolute top-[50%] left-6 transform -translate-y-1/2  text-white px-4 md:px-8 w-full ">
                    <p className='text-[4vw]'>
                        Luxlife.

                    </p>
                    <h1 className="text-[24px] md:text-[48px] lg:text-[3.2vw] creato-f-black tracking-wide mx-auto">
                        New Development
                    </h1>
                    <p className="text-[16px] md:text-[20px] lg:text-[23px] creato-f-r mt-2 max-w-[500px]">
                        Discover all of Miami's new preconstruction
                        condo developments.                    </p>
                    <div className='flex  mt-14'>
                        <Button title={<div className='flex items-center gap-4'><span>View more</span> <span><IoIosArrowForward className='text-[20px]' /></span></div>} className={'bg-theme_yellow px-8 text-theme_blue rounded-full py-3 creato-f-m  tracking-wide btn2'} />
                    </div>
                </div>
            </div>


            <div className="container lg:max-w-[1500px] mx-auto px-4">

                <div className="flex justify-center text-center md:text-start lg:justify-between flex-col lg:flex-row items-center lg:items-baseline mt-16 mb-8">
                    <h1 className="creato-f-black text-theme_blue text-[25px] leading-[30px] md:text-[65px] md:leading-[65px]">
                        Next-Gen <br />
                        New Developments
                    </h1>
                    <p className="max-w-[600px] lg:text-end md:text-[30px] text-wrap break-words mt-4 lg:mt-0">
                        Explore transformative new buildings that redefine modern luxury
                        living.
                    </p>

                </div>

                <div className="flex flex-wrap gap-4 lg:gap-6 justify-center py-8">
                    {[...cardData, ...cardData, ...cardData].map((card) => (
                        <div
                            key={card.id}
                            className="relative dark-bottom-overlay overflow-hidden group cursor-pointer max-w-[460px] rounded-xl"
                        >
                            <img
                                alt={card.title}
                                src={card.image}
                                className="group-hover:scale-[1.1] transition duration-300 w-[100%] black_white_effect"
                            />
                            <div className="absolute z-30 bottom-4 w-full px-4 text-white">
                                <p className="text-[22px] leading-[33px] poppins-semibold">
                                    {card.title}
                                </p>
                                <div className="p-2 flex gap-1 items-center">
                                    <CiLocationOn className="text-white text-[23px] transform transition-transform duration-700" />
                                    <p className="tracking-wider">{card.location}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </ContactLayout>
    );
};

export default PreConstruct;
