import React from 'react'
import Hero from '../components/ui/sections/HeroSection/Hero'
import Section2 from '../components/ui/sections/section_2/Section2'
import Section3 from '../components/ui/sections/section_3/Section3'
import Section4 from '../components/ui/sections/section_4/Section4'
import Section5 from '../components/ui/sections/section5/Section5'
import ContactUs from '../components/ui/sections/ContactUsSection/ContactUs'
import ChatWidget from '../components/chat/ChatBox'
import MeetExpertSec from '../components/ui/sections/meet_expert_section/MeetExpertSec'
import Footer from '../components/ui/sections/FooterSection/Footer'

const Home = () => {
    return (
        <div className="">
            <Hero />
            <Section2 />
            <Section3 />
            <Section4 />
            <MeetExpertSec />
            <Section5 />
            <ContactUs />
            <ChatWidget />
            <Footer />
        </div>
    )
}

export default Home