import React from 'react';
import FooterLogo from '../../../../assets/images/sec5_logo.svg'
import FooterOwner from '../../../../assets/images/daniel_footer.svg'
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaWhatsapp } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { IoIosMail } from 'react-icons/io';
import AnimationDiv from '../../../animation/AnimationDiv';
import { Link } from 'react-router-dom';
import { cn } from '../../../../lib/utils';
const Footer = ({ className }) => {
    return (
        <footer className={cn("bg-[#D3962A] text-white pt-14 pb-10 md:pb-20 mt-10 px-6 lg:px-20 ", className)}>
            <div className='mx-auto max-w-[1598px]'>
                <div className="flex gap-10 flex-wrap  xl:flex-nowrap">
                    {/* Left Section */}
                    <div className='lg:pt-12 w-full min-w-[320px] flex flex-col items-center lg:items-start'>
                        <AnimationDiv
                            initial={{ opacity: 0, x: -100 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ duration: 1 }}
                            viewport={{ once: true }}
                        >
                            <div className=''>
                                <img src={FooterLogo} alt='logo' className='max-w-[130px] lg:max-w-[280px]' />
                            </div>
                        </AnimationDiv>
                        <div className='flex flex-col-reverse lg:flex-row flex-1 w-full justify-between '>
                            <AnimationDiv
                                initial={{ opacity: 0, x: -40 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                transition={{ duration: 1 }}
                                viewport={{ once: true }}
                            >
                                <div className='border-t-2 mt-6 lg:mt-0 lg:border-t-0'>

                                    <h2 className="poppins-bold text-[22px] md:text-[44px] leading-[66px] mt-[30px]">Luxlife Miami Real Estate LLC</h2>
                                    <p className="text-[12px] md:text-[20px]  md:leading-[24px] leading-[17px] poppins-regular max-w-[627px] ">
                                        Luxlife Miami Real Estate LLC is dedicated to helping you find the perfect home. With a vast selection of properties and a team of expert agents, we provide personalized services to meet all your real estate needs. Discover the difference with LUXLIFE!
                                    </p>

                                    <address className="not-italic text-[14px] md:text-[25px] creato-f-l md:leading-[33px] leading-[17px] mt-[24px]">
                                        1500 5th Street Suite 254,<br />
                                        Miami Beach, FL 33139<br />
                                        <strong className='creato-f-b '>Phone:</strong> 1.234.56.MIAMI (1234)<br />
                                        <strong className='creato-f-b '>Email:</strong> info@luxlifemiamirealestate.com<br />
                                        <strong className='creato-f-b '>Web:</strong> www.luxlifemiamirealestate.com
                                    </address>

                                    <div className="mt-[22px] lg:mt-[37.5px] "><p className='poppins-medium text-[12px] md:text-[22px] leading-[17px] md:leading-[27px] max-w-[627px]'>Stay updated with the latest listings and real estate news. Subscribe to our newsletter</p></div>
                                    <div className=" items-center mt-[19px] max-w-[630px]">
                                        <input
                                            type="email"
                                            placeholder="input your email address"
                                            className="px-[32px] py-4 rounded-xl outline-none bg-transparent border-white border w-full text-white placeholder:text-white placeholder:font-normal placeholder:opacity-100 poppins-regular text-[12px] md:text-[16px]"
                                        />
                                        <Link className='' to={'/subscribe'} >
                                            <button className="bg-white  btn-primary text-theme_yellow px-8 py-2 rounded-lg poppins-semibold mt-4  lg:mt-[25px] text-[12px] md:text-base">Subscribe</button>
                                        </Link>
                                    </div>
                                </div>
                            </AnimationDiv>
                            <AnimationDiv
                                initial={{ opacity: 0, x: -20 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                transition={{ duration: 1 }}
                                viewport={{ once: true }}
                            >
                                <div className="flex flex-col lg:mt-[-50px] lg:ml-8 lg:mr-16">
                                    <h3 className="hidden  lg:block poppins-bold text-[30px] leading-[36px] text-nowrap  mb-4">Quick Links</h3>
                                    <ul className=" text-sm flex flex-wrap gap-4 md:gap-0 items-center justify-center lg:flex-col lg:items-start lg:justify-start mt-10 md:mt-0" >
                                        <li className='poppins-regular text-[12px] md:text-[20px] leading-[27px] lg:mt-[20px] nav-item nav-white '>Home</li>
                                        <li className='poppins-regular text-[12px] md:text-[20px] leading-[27px] lg:mt-[20px] nav-item nav-white '>Listings</li>
                                        <li className='poppins-regular text-[12px] md:text-[20px] leading-[27px] lg:mt-[20px] nav-item nav-white '>Agents</li>
                                        <li className='poppins-regular text-[12px] md:text-[20px] leading-[27px] lg:mt-[20px] nav-item nav-white '>Services</li>
                                        <li className='poppins-regular text-[12px] md:text-[20px] leading-[27px] lg:mt-[20px] nav-item nav-white '>Testimonials</li>
                                        <li className='poppins-regular text-[12px] md:text-[20px] leading-[27px] lg:mt-[20px] nav-item nav-white '> <Link className='' to={'/contact'} >Contact</Link></li>
                                        <li className='poppins-regular text-[12px] md:text-[20px] leading-[27px] lg:mt-[20px] nav-item nav-white '>Meet Daniel</li>
                                        <li className='poppins-regular text-[12px] md:text-[20px] leading-[27px] lg:mt-[20px] nav-item nav-white '>Sell my Property</li>
                                        <li className='poppins-regular text-[12px] md:text-[20px] leading-[27px] lg:mt-[20px] nav-item nav-white '>Login</li>
                                        <li className='poppins-regular text-[12px] md:text-[20px] leading-[27px] lg:mt-[20px] nav-item nav-white '>Top 100</li>
                                    </ul>
                                    <h3 className="poppins-bold text-[30px] leading-[36px] hidden lg:block mt-14">Legal</h3>
                                    <ul className="space-y-2 text-sm hidden lg:flex flex-col text-nowrap   justify-center items-start  ">
                                        <li className='poppins-regular text-[12px] md:text-[20px] leading-[27px] lg:mt-[20px] nav-item nav-white '>Privacy Policy</li>
                                        <li className='poppins-regular text-[12px] md:text-[20px] leading-[27px] lg:mt-[20px] nav-item nav-white  '>Terms of Service</li>
                                    </ul>
                                </div>
                            </AnimationDiv>
                        </div>
                    </div>

                    {/* Middle Section */}


                    {/* Right Section */}
                    <div className="flex flex-col items-center lg:items-start mt-12 mx-auto  lg:min-w-[440px]">

                        <div className='relative bg-theme_blue max-w-[214px] lg:max-w-[437px] flex justify-center items-baseline h-[240px] lg:h-[437px] w-full lg:rounded-tl-[160px] rounded-tl-[80px] lg:rounded-br-[160px] rounded-br-[80px]'>
                            <AnimationDiv
                                initial={{ opacity: 0, x: 100 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                transition={{ duration: 1 }}
                                viewport={{ once: true }}
                                className={'w-full flex justify-center'}
                            >
                                <img
                                    src={FooterOwner} // Replace with the actual image URL or import locally
                                    alt="Daniel Pansky"
                                    className="w-[100%] lg:w-[90%] mt-[-23px] lg:mt-[-38px] object-cover  "
                                />
                            </AnimationDiv>
                        </div>
                        <h3 className="font-bold text-[36px] leading-[43px] mt-[16px] creato-f-b t">DANIEL PANSKY</h3>
                        <div className="flex flex-col sm:flex-row sm:space-x-2 mt-[18px] creato-f-m">
                            <AnimationDiv
                                initial={{ opacity: 0, x: -100 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                transition={{ duration: 1 }}
                                viewport={{ once: true }}
                            >

                                <button className="bg-white w-[200px] btn-primary  py-3 px-8 rounded-xl leading-[33px] flex  items-center gap-2 text-[20px] justify-center text-theme_yellow"><span className=''><FaWhatsapp /></span> <span>Whatsapp</span></button>
                            </AnimationDiv>
                            <AnimationDiv
                                initial={{ opacity: 0, x: 100 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                transition={{ duration: 1 }}
                                viewport={{ once: true }}
                            >

                                <button className="bg-white   w-[200px] btn-primary  py-3 px-8 mt-4 sm:mt-0 rounded-xl leading-[33px] text-[20px] justify-center flex  items-center gap-2 text-theme_yellow"><span className=''><IoIosMail /></span> <span>Email Me</span></button>
                            </AnimationDiv>
                        </div>

                        <div>
                            <div className='hidden lg:block'>
                                <h1 className='poppins-bold text-[30px] leading-[36px] mt-[25px]'>Follow Us</h1>
                            </div>
                            <div>
                                <div className="mt-[26px] flex gap-8 lg:gap-0 lg:flex-col ">

                                    <div className='flex gap-8'>
                                        <AnimationDiv
                                            initial={{ opacity: 0, x: -50 }}
                                            whileInView={{ opacity: 1, x: 0 }}
                                            transition={{ duration: .5 }}
                                            viewport={{ once: true }}
                                        >
                                            <a href="#" className="flex  items-center text-[12px]  md:text-[20px] poppins-regular gap-10"><span className='bg-white rounded-full  btn-primary text-theme_yellow text-[24px] p-3'> <FaFacebookF className=' md:text-[30px] ' /></span> <span className='hidden lg:block'>Facebook</span></a> {/* Facebook Icon */}
                                        </AnimationDiv>
                                        <AnimationDiv
                                            initial={{ opacity: 0, x: 50 }}
                                            whileInView={{ opacity: 1, x: 0 }}
                                            transition={{ duration: .5 }}
                                            viewport={{ once: true }}
                                        >

                                            <a href="#" className="flex  items-center text-[12px] md:text-[20px] poppins-regular gap-10"><span className='bg-white rounded-full  btn-primary text-theme_yellow text-[24px] p-3'><FaXTwitter className=' md:text-[30px] ' /></span><span className='hidden lg:block'>Twitter</span></a> {/* Twitter Icon */}

                                        </AnimationDiv>   </div>
                                    <div className='flex gap-8 lg:mt-4'>
                                        <AnimationDiv
                                            initial={{ opacity: 0, x: -50 }}
                                            whileInView={{ opacity: 1, x: 0 }}
                                            transition={{ duration: .5 }}
                                            viewport={{ once: true }}
                                        >
                                            <a href="#" className="flex  items-center text-[12px] md:text-[20px] poppins-regular gap-10"><span className='bg-white rounded-full  btn-primary text-theme_yellow text-[24px] p-3'> <FaInstagram className=' md:text-[30px] ' /></span><span className='hidden lg:block'>Instagram</span></a> {/* Instagram Icon */}
                                        </AnimationDiv>
                                        <AnimationDiv
                                            initial={{ opacity: 0, x: 50 }}
                                            whileInView={{ opacity: 1, x: 0 }}
                                            transition={{ duration: .5 }}
                                            viewport={{ once: true }}
                                        >
                                            <a href="#" className="flex  items-center text-[12px] md:text-[20px] poppins-regular gap-10"><span className='bg-white rounded-full  btn-primary text-theme_yellow text-[24px] p-3'>< FaLinkedinIn className=' md:text-[30px] ' /></span><span className='hidden lg:block'>Linkdin</span></a> {/* LinkedIn Icon */}
                                        </AnimationDiv>  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-10 md:mt-20 flex gap-4 items-center justify-center text-center text-sm pb-2">
                    <p className='poppins-semibold text-[10px] md:text-[20px] md:leading-[30px] '> © 2024 Luxlife Miami Real Estate LLC. All rights reserved.</p>
                    <div> <ul className=" text-nowrap  lg:hidden flex flex-col  justify-center items-start  ">
                        <li className='poppins-regular text-[10px] border-b-white border-b '>Privacy Policy</li>
                        <li className='poppins-regular text-[10px] border-b-white border-b  '>Terms of Service</li>
                    </ul></div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
