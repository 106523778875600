import React from 'react';
import Footer from '../../../components/ui/sections/FooterSection/Footer';
import Header from '../../../components/ui/header/Header';
import ContactUs from '../../../components/ui/sections/ContactUsSection/ContactUs';

const ContactLayout = ({ children }) => {
    return (
        <>
            <header className="bg-theme_blue">
                <Header />
            </header>
            <main>
                {children}
            </main>
            <section>
                <ContactUs className="mt-0" />
            </section>
            <Footer />
        </>
    );
};

export default ContactLayout;
