import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import InvestmentMiami from './pages/investment-miami';
import Contact from './pages/Contact';
import Subscribe from './pages/Subscribe';
import MapPage from './pages/MapPage';
import New100Listing from './pages/New100Listing';
import { ConfigProvider } from 'antd';
import HomesPage from './pages/HomesPage';
import Condos from './pages/Condos';
import SellProperties from './pages/SellProperties';
import About from './pages/About';
import SingleProperty from './pages/SingleProperty';
import ScrollToTop from './components/Scroll/ScrollTop';
import PreConstruct from './pages/PreConstruct';
import SinglePreContruction from './pages/SinglePreConstruction';
import { LoginSignUp } from './components/auth/LoginSignup';

function App() {
  return (
    <ConfigProvider
      theme={{
        components: {
          Slider: {
            handleColor: '#E4A231'
            , handleActiveOutlineColor: '#E4A231',
            handleLineWidth: 5

          },
        },
        token: {
          // Seed Token
          colorPrimary: '#E4A231',

          // Alias Token
          colorBgContainer: '#f7f5f5',

        },
      }}
    >
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/homes' element={<HomesPage />} />
          <Route path='/about' element={<About />} />
          <Route path='/Condos' element={<Condos />} />
          <Route path='/miami/:slug' element={<InvestmentMiami />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/subscribe' element={<Subscribe />} />
          <Route path='/map' element={<MapPage />} />
          <Route path='/new-100/:tab' element={<New100Listing />} />
          <Route path='/sell-properties' element={<SellProperties />} />
          <Route path='/single-property' element={<SingleProperty />} />
          <Route path='/pre-construction' element={<PreConstruct />} />
          <Route path='/single-pre-construction' element={<SinglePreContruction />} />
          <Route path="/login" element={<LoginSignUp title="Login" />} />
          <Route path="/signup" element={<LoginSignUp title="Sign Up" />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
