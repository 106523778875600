import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, Spin, Upload } from 'antd';
import { FaPlus } from 'react-icons/fa';
import axios from 'axios';
// import baseUrl from "../../lib/endpoint.json";
import { ToastContainer, toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import Header from '../ui/header/Header'
import Footer from '../ui/sections/FooterSection/Footer';
export const LoginSignUp = ({ title }) => {
    const [fileList, setFileList] = useState([]);
    const [isOTP, setisOTP] = useState(null);
    const [otp, setOtp] = useState('');
    const [isSpinning, setisSpinning] = useState(false);
    const [timer, setTimer] = useState(60);
    const [canResend, setCanResend] = useState(false);
    const navigate = useNavigate();

    const handleChange = ({ fileList }) => {
        setFileList(fileList);
    };

    useEffect(() => {
        setisOTP(localStorage.getItem('otp'));
    }, []);

    useEffect(() => {
        let interval;
        if (isOTP) {
            interval = setInterval(() => {
                setTimer(prevTimer => {
                    if (prevTimer <= 1) {
                        clearInterval(interval);
                        setCanResend(true);
                        return 0;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isOTP]);

    const onFinish = (values) => {
        setisSpinning(true);
        if (title === 'Sign Up') {
            if (values.password !== values.c_password) {
                setisSpinning(false);
                toast.error('Password should match with confirm Password');
            } else if (!values.password?.trim() || !values.c_password?.trim()) {
                setisSpinning(false);
                toast.error('Password is required');
            } else if (fileList.length < 2) {
                toast.error('Both Front and Back CNIC is required');
                setisSpinning(false);
            } else {
                const formData = new FormData();
                formData.append('name', values.name);
                formData.append('email', values.email);
                formData.append('phone', values.phone);
                formData.append('password', values.password);
                formData.append('c_password', values.c_password);
                // Append more keys as needed
                formData.append('cnic_front', fileList[0]?.originFileObj);
                formData.append('cnic_back', fileList[1]?.originFileObj);

                // axios.post(`${baseUrl.baseURL}api/register`, formData, {
                //     headers: {
                //         'Content-Type': 'multipart/form-data'
                //     }
                // })
                //     .then((res) => {
                //         setisSpinning(false);
                //         setisOTP(true);
                //         localStorage.setItem('otp', true);
                //         localStorage.setItem('signup_email', res.data.data.email);
                //     })
                //     .catch((err) => {
                //         // errorAlert(err);
                //         setisSpinning(false);
                //     });
            }
        } else {
            const formData = new FormData();
            formData.append('email', values.email);
            formData.append('password', values.password);

            // axios.post(`${baseUrl.baseURL}api/login`, formData)
            //     .then((res) => {
            //         localStorage.setItem('user_data', JSON.stringify(res.data.data));
            //         setisSpinning(false);
            //         navigate('/');
            //     })
            //     .catch((err) => {
            //         console.log(err.response.data.data);
            //         toast.error('Invalid Email or Password');
            //         setisSpinning(false);
            //     });
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const email = localStorage.getItem('signup_email') ? localStorage.getItem('signup_email') : '';
    let email_length = email.length;
    let first = email.substring(0, 5);
    let last = email.substring(email_length - 4, email_length);
    let final = first + '*********' + last;

    const onOTPChange = (val) => {
        // const email = localStorage.getItem('signup_email');
        // const formData = new FormData();
        // formData.append('otp', val);
        // formData.append('email', email);

        // axios.post(`${baseUrl.baseURL}api/emailVerify`, formData)
        //     .then((res) => {
        //         localStorage.removeItem('otp');
        //         navigate('/login');
        //     })
        //     .catch((err) => {
        //         if (err.response?.data && err.response.data.data) {
        //             toast.error(err.response.data.data);
        //         }
        //     });
    };

    const resendOTP = () => {
        setCanResend(false);
        setTimer(60);
        const email = localStorage.getItem('signup_email');
        const formData = new FormData();
        formData.append('email', email);

        // axios.post(`${baseUrl.baseURL}api/sendotp`, formData)
        //     .then((res) => {
        //         toast.success('OTP has been resent');
        //         // Restart the timer
        //         setisOTP(false); // This will trigger the useEffect to restart the timer
        //         setTimeout(() => {
        //             setisOTP(true);
        //         }, 0);
        //     })
        //     .catch((err) => {
        //         if (err.response?.data && err.response.data.data) {
        //             toast.error(err.response.data.data);
        //         }
        //     });
    };

    return (
        <>
            <div className='w-full bg-theme_blue'>
                <Header />
            </div>
            {
                isOTP ? (
                    <div className='mt-11'>
                        <h1 className='poppins-bold text-[40px]'>Verify OTP</h1>
                        <div className='max-w-[600px] text-center border-theme_yellow2 border shadow-2xl mx-auto my-10 rounded-lg p-4'>
                            <h5 className="mt-5 mb-3">Please enter the one-time password to verify your account</h5>
                            <div> <span>A code has been sent to</span> <small className='onlyColor' style={{ fontWeight: 700 }}>{final}</small></div>
                            <div id="otp" className="inputs d-flex flex-row justify-content-center mt-4">
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    isInputNum={true}
                                    shouldAutoFocus={true}
                                    inputStyle={{
                                        width: "3rem",
                                        height: "3rem",
                                        margin: "0 1rem",
                                        fontSize: "1.2rem",
                                        borderRadius: 4,
                                        fontWeight: 400,
                                        outline: "none",
                                        boxShadow: "0px 2px 20px rgba(0,0,0,0.07)",
                                        border: "1px solid #bd1e2e9c"
                                    }}
                                    separator={<span>-</span>}
                                    renderInput={(props) => <input {...props} />}
                                />
                            </div>
                            <div className="mt-4">
                                <button className="bg-theme_yellow2 text-white text-lg py-2 px-4 rounded-lg focus:outline-none w-full" disabled={otp.length !== 4} onClick={() => onOTPChange(otp)}>Validate</button>
                            </div>
                            <div className="mt-4">
                                <span>{timer > 0 ? `Resend OTP in ${timer}s` : ''}</span>
                                <button style={{ color: '#FCB527' }} className="px-3" disabled={!canResend} onClick={resendOTP}>Resend OTP</button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Form
                        name="basic"
                        style={{ maxWidth: 500 }}
                        labelAlign={'left'}
                        layout="vertical"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        className='mx-auto mt-11'
                    >
                        <div className='max-w-[600px] border-theme_yellow2 border shadow-2xl mx-auto  rounded-lg p-4'>
                            <div className='mt-4 flex my-10 justify-center text-theme_yellow'>
                                <h1 className='poppins-bold text-[40px]'>{title}</h1>
                            </div>
                            {title === "Sign Up" && (
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    rules={[{ required: true, message: 'Please input your Name!' }]}
                                >
                                    <Input />
                                </Form.Item>
                            )}
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[{ required: true, message: 'Please input your Email!' }]}
                            >
                                <Input />
                            </Form.Item>
                            {title === 'Sign Up' && (
                                <Form.Item
                                    label="Phone"
                                    name="phone"
                                    rules={[{ required: true, message: 'Please input your Phone Number!' }]}
                                >
                                    <Input type='number' />
                                </Form.Item>
                            )}
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    { required: true, message: 'Please input your password!' },
                                    {
                                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
                                        message: 'Password must be at least 6 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.',
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                            {title === 'Login' && (
                                <p className='mb-8'>Don't have an account? <Link className='text-theme_yellow' to={'/signup'}>Sign Up</Link ></p>
                            )}
                            {title === "Sign Up" && (
                                <Form.Item
                                    label="Confirm Password"
                                    name="c_password"
                                    rules={[{ required: true, message: 'Please input your Confirm password!' }]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            )}
                            {title === 'Sign Up' && (
                                <p className='mb-8'>Have an account <Link className='text-theme_yellow' to={'/login'}>Sign in</Link ></p>
                            )}
                            <Form.Item>
                                <Spin spinning={isSpinning}>
                                    <Button type="primary" htmlType="submit" style={{ backgroundColor: "#ffca4f", width: '100%' }} className='px-8'>
                                        Submit
                                    </Button>
                                </Spin>
                            </Form.Item>
                        </div>
                    </Form>
                )
            }
            <ToastContainer />
            <Footer />
        </>
    );
};
