import React from 'react'
import SimpleSearch from '../../forms/searchbar/SimpleSearch'
import SingleSelect from '../../forms/select/SingleSelect';
const priceOptions = [
    { value: 'under_50k', label: 'Under $50,000' },
    { value: '50k_100k', label: '$50,000 - $100,000' },
    { value: '100k_200k', label: '$100,000 - $200,000' },
    { value: '200k_500k', label: '$200,000 - $500,000' },
    { value: '500k_plus', label: 'Over $500,000' },
];

export const bedsOptions = [
    { value: '1_bed', label: '1 Bed' },
    { value: '2_beds', label: '2 Beds' },
    { value: '3_beds', label: '3 Beds' },
    { value: '4_beds', label: '4 Beds' },
    { value: '5_plus_beds', label: '5+ Beds' },
];

export const bathOptions = [
    { value: '1_bath', label: '1 Bath' },
    { value: '2_baths', label: '2 Baths' },
    { value: '3_baths', label: '3 Baths' },
    { value: '4_plus_baths', label: '4+ Baths' },
];

const saleOptions = [
    { value: 'for_sale', label: 'For Sale' },
    { value: 'for_rent', label: 'For Rent' },
    { value: 'all_types', label: 'All Types' },
];

const Filter = () => {

    //  <>
    //             <div className='flex gap-8'>
    //                 <div className='flex-1 max-w-[600px]'>
    //                     <p className='pl-2 text-white'>Search By Location</p>
    //                     <SimpleSearch placeholder={'Enter a location or a Keyword'} className={' w-full min-w-[150px] md:min-w-[300px] xl:min-w-[460px]  rounded-[20px]'} />
    //                 </div>

    //                 <div className='flex gap-8'>
    //                     <div className='flex-1'>
    //                         <p className='pl-2 text-white'>Price Range</p>
    //                         <SingleSelect clsMain={'w-full tracking-wider min-w-[150px]'} options={priceOptions} placeholder="Min Price - Max Price" />
    //                     </div>

    //                     <div className='flex-1'>
    //                         <p className='pl-2 text-white'>Beds</p>
    //                         <SingleSelect clsMain={'w-full tracking-wider  min-w-[100px]'} options={bedsOptions} placeholder="Any" />
    //                     </div>

    //                     <div className='flex-1'>
    //                         <p className='pl-2 text-white'>Bath</p>
    //                         <SingleSelect clsMain={'w-full tracking-wider min-w-[100px]'} options={bathOptions} placeholder="Any" />
    //                     </div>

    //                     <div className='flex-1'>
    //                         <p className='pl-2 text-white'>For Sale/All Type</p>
    //                         <SingleSelect clsMain={'w-full tracking-wider min-w-[150px]'} options={saleOptions} placeholder="For Sale/All Type" />
    //                     </div>
    //                 </div>

    //             </div>
    //             <div className='flex items-end'>
    //                 <button className='p-4 bg-white rounded-[20px] text-[19px] creato-f-r lg:w-[213px] text-theme_yellow btn-primary'> Save Search</button>
    //             </div></>
    return (
        <>
            <div className='flex gap-8 w-full flex-wrap'>
                <div className='flex-1 max-w-[600px]'>
                    <p className='pl-2 text-white'>Search By Location</p>
                    <SimpleSearch placeholder={'Enter a location or a Keyword'} className={' w-full min-w-[150px] md:min-w-[300px] xl:min-w-[460px]  rounded-[20px]'} />
                </div>


                <div className='flex-1'>
                    <p className='pl-2 text-white'>Price Range</p>
                    <SingleSelect clsMain={'w-full tracking-wider min-w-[150px]'} options={priceOptions} placeholder="Min Price - Max Price" />
                </div>

                <div className='flex-1'>
                    <p className='pl-2 text-white'>Beds</p>
                    <SingleSelect clsMain={'w-full tracking-wider  min-w-[100px]'} options={bedsOptions} placeholder="Any" />
                </div>

                <div className='flex-1'>
                    <p className='pl-2 text-white'>Bath</p>
                    <SingleSelect clsMain={'w-full tracking-wider min-w-[100px]'} options={bathOptions} placeholder="Any" />
                </div>

                <div className='flex-1'>
                    <p className='pl-2 text-white'>For Sale/All Type</p>
                    <SingleSelect clsMain={'w-full tracking-wider min-w-[150px]'} options={saleOptions} placeholder="For Sale/All Type" />
                </div>


                <div className='flex items-end'>
                    <button className='p-4 bg-white rounded-[20px] text-[19px] creato-f-r lg:w-[213px] text-theme_yellow btn-primary'> Save Search</button>
                </div>
            </div>
        </>
    )
}

export default Filter