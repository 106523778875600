// MapComponent.jsx
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { FaCircle } from 'react-icons/fa6';

// Fix marker icons issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Custom Marker Icon using FaCircle
const customIcon = L.divIcon({
    className: 'custom-marker', // Optional: Add custom class for styling
    html: `<div style="width: 20px; height: 20px; border-radius:10px; border:4px white solid; background-color: black;"></div>`, // Styling the FaCircle icon
    iconSize: [30, 30], // Optional: Adjust the size of the icon container
});

const MapComponent = () => {
    const position = [51.505, -0.09]; // Coordinates for the initial center of the map

    return (
        <MapContainer center={position} zoom={13} style={{ height: '100%', width: '100%' }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={position} icon={customIcon}>
                <Popup>A sample popup message</Popup>
            </Marker>
        </MapContainer>
    );
};

export default MapComponent;
