import React, { useState } from 'react'
import ContactLayout from '../components/ui/layout/ContactLayout'
import MapComponent from '../components/map/Map'
import Button from '../components/buttons/Button'
import AnimationDiv from '../components/animation/AnimationDiv';
import sec5Logo from '../assets/images/sec5_logo.svg'
import card1 from '../assets/images/card1.png'
import card2 from '../assets/images/card2.png'
import card3 from '../assets/images/card3.png'
import arrowRight from '../assets/images/arrowRight.svg'
import crown from '../assets/images/crown.svg'
import { motion } from 'framer-motion';
const locations = [
    'Aventura', 'Bal Harbour', 'Bay Harbor Islands', 'Boca Raton', 'Coral Gables',
    'Fisher Island', 'Fort Lauderdale', 'Golden Beach', 'Hallandale Beach', 'Hollywood',
    'Key Biscayne', 'Miami', 'North Bay Village', 'North Miami', 'North Miami Beach',
    'Pompano Beach', 'Sunny Isles Beach', 'Surfside', 'West Palm Beach',
    'Coconut Grove', 'Design District', 'Downtown Miami', 'Edgewater', 'Upper East Side',
    'Wynwood and Midtown', 'Allison Island', 'Biscayne Point', 'Central',
    'Flamingo - Lummus', 'Historic District', 'Indian Creek Island', 'La Gorce Country Club',
    'La Gorce Island', 'Mid-Beach', 'Nautilus', 'Normandy Isles', 'North Bay Road',
    'North Beach', 'Pinetree Drive (Waterfront)', 'South Beach'
];
const images = [card1, card2, card3];
export const LocationCard = ({ locationName, imageSrc, arrowSrc, index }) => {
    // Variants for the animation
    const cardVariants = {
        hidden: { opacity: 0, x: index % 2 === 0 ? -50 : 50 }, // slide left for even, right for odd
        visible: { opacity: 1, x: 0 },
    };

    return (
        <motion.div
            className='min-w-[300px] mt-5 flex justify-between items-center bg-white pr-4 md:pr-8'
            variants={cardVariants}
            initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.5, delay: index * 0.01 }} // delay based on index for stagger effect
            viewport={{ once: true }} // Ensures animation happens only once
        >
            <div className='flex items-center gap-6 md:gap-12'>
                <img src={imageSrc} className='max-w-[100px] lg:max-w-[135px] lg:h-[90px] h-[65px]  object-cover' alt={locationName} />
                <p className='creato-f-r text-theme_blue lg:text-[24px] lg:leading-[34px] tracking-wide'>
                    {locationName}
                </p>
            </div>
            <div>
                <img src={arrowSrc} alt='Arrow' className='max-w-[12px] md:max-w-full' />
            </div>
        </motion.div>
    );
};

const MapPage = () => {

    const [selectedLocations, setSelectedLocations] = useState([]);

    const toggleLocation = (location) => {
        if (selectedLocations.includes(location)) {
            setSelectedLocations(selectedLocations.filter((item) => item !== location));
        } else {
            setSelectedLocations([...selectedLocations, location]);
        }
    };

    const resetSelection = () => {
        setSelectedLocations([]);
    };

    return (
        <>
            <ContactLayout>
                <div className='flex flex-wrap flex-col-reverse lg:flex-row '>
                    <div className='flex-1 '>
                        <div className="p-[60px]">
                            <div className='max-w-[750px] md:leading-[72px]'>
                                <h1 className='creato-f-b md:text-[63px]'>Explore What's For Sale or Rent in Miami and South Florida</h1>
                                <div className='bg-theme_blue md:h-[7px] md:w-[90px] h-[3px] w-[50px] mt-3'></div>
                                <div className='flex items-center justify-between gap-4 md:gap-0'>
                                    <p className='text-[12px] md:text-[25px] md:leading-[33px] mt-4'>
                                        Set below what best describes your property needs:
                                    </p>

                                    <Button onClick={resetSelection} title={'Reset'} className={'flex items-center justify-center h-10 px-4 md:px-8 rounded-lg py-0 text-[12px] md:text-[20px]  md:leading-[27px] creato-f-r'} />
                                </div>
                            </div>
                            <div className=''>
                                <div className="py-6">
                                    <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-x-2 mb-4 ">
                                        {locations.map((location) => (
                                            <button
                                                key={location}
                                                onClick={() => toggleLocation(location)}
                                                className={`text-left ${selectedLocations.includes(location) ? 'text-theme_yellow' : 'text-theme_grey2'
                                                    } hover:text-theme_yellow transition duration-200 `}
                                            >
                                                <p className='segoeui lg:text-[1vw] text-nowrap'> {selectedLocations.includes(location) ? <span className='flex '><span className='text-bold text-4xl leading-[20px] mr-1'>-</span> {location}</span> : `+ ${location}`}</p>
                                            </button>
                                        ))}
                                    </div>

                                </div>

                                <div className='flex justify-center '> <Button className={'mx-auto rounded-lg px-12 py-3 creato-f-r md:text-[22px] tracking-wide leading-[38px]'} title={'See Properties'} /> </div>
                            </div>

                        </div>
                    </div>
                    <div className='lg:flex-1  h-[300px] lg:h-[100vh]' ><MapComponent /></div>
                </div>
                <div className='my-24 min-h-96 bg-theme_blue md:p-10  md:rounded-tr-[250px] md:rounded-bl-[250px] rounded-tr-[220px] rounded-bl-[220px] md:sec_5_bg ' >
                    <div className='py-8 pb-24 md:px-4 mx-[5%]'>
                        <div className='flex justify-between flex-wrap-reverse mx-[3%]'>
                            <div>
                                <AnimationDiv
                                    initial={{ opacity: 0, x: -20 }}
                                    whileInView={{ opacity: 1, x: 0 }}
                                    transition={{ duration: 1 }}
                                    viewport={{ once: true }}
                                >
                                    <h1 className='text-[32px] md:text-[55px] lg:text-[63px] leading-[30px] md:leading-[62px] lg:leading-[64px] text-white font-bold my-2 creato-f-b'>
                                        Explore Miami Condos
                                    </h1>
                                </AnimationDiv>
                                <p className='text-white text-[16px] leading-[27px] md:text-[18px] creato-f-r max-w-[774px]'>
                                    Discover Your Dream Home with Luxlife Properties
                                </p>
                            </div>
                            <div>
                                <AnimationDiv
                                    initial={{ opacity: 0, x: 20 }}
                                    whileInView={{ opacity: 1, x: 0 }}
                                    transition={{ duration: 1 }}
                                    viewport={{ once: true }}
                                >
                                    <img src={sec5Logo} alt="" className='max-w-[100px] md:max-w-[225px]' />
                                </AnimationDiv>
                            </div>
                        </div>
                        <div className='max-w-[1500px] gap-x-6 lg:gap-x-[100px] mx-auto mt-14 flex flex-wrap justify-between  grid grid-cols-1 md:grid-cols-2 '>

                            {locations.map((location, index) => (
                                <LocationCard
                                    key={location}
                                    locationName={location}
                                    imageSrc={images[index % images.length]} // Cycle through images
                                    arrowSrc={arrowRight}
                                    index={index}
                                />
                            ))}
                        </div>

                        <div className='flex justify-center mt-32'>
                            <img src={crown} alt='' />
                        </div>
                    </div>
                </div>
            </ContactLayout>
        </>
    )
}

export default MapPage