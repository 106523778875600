import React from 'react'
import ContactLayout from '../components/ui/layout/ContactLayout'
import BlueBgListView from '../components/ui/sections/blueBgSection/list-view-section'
import BlueBgCardView from '../components/ui/sections/blueBgSection/card-view-section'
import Button from '../components/buttons/Button'
import { IoIosArrowForward } from 'react-icons/io'

const HomesPage = () => {
    return (
        <ContactLayout>
            <BlueBgListView title={'Miami Homes'} />
            <div className='mb-24'>
                <BlueBgCardView title={'Homes'} >
                    <div className='flex justify-center mt-14'>
                        <Button title={<div className='flex items-center gap-4'><span>View more</span> <span><IoIosArrowForward className='text-[20px]' /></span></div>} className={'bg-white px-8 text-theme_yellow rounded-md py-3 creato-f-m  tracking-wide btn2'} />
                    </div>
                </BlueBgCardView>
            </div>
        </ContactLayout>
    )
}

export default HomesPage